<template>
  <div class="h-100 d-flex flex-column" v-if="$store.state.brandSignupPage">
    <header
      class="ts-contact-form-container__header ts-text-gray-4 text-center my-0"
    >
      <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
        {{ $store.state.brandSignupPage.Form3.title }}
      </h1>
      <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-0">
        {{ $store.state.brandSignupPage.Form3.description }}
      </p>
    </header>
    <form class="my-auto">
      <p class="ts-sentyou-otp ts-fs-7 fw-light mb-03 text-center mb-04">
        {{ $store.state.brandSignupPage.Form3.description2 }}
      </p>

      <div class="ts-otp-input">
        <v-otp-input
          ref="otpInput"
          v-model:value="bindModal"
          input-classes="otp-input"
          separator=""
          :num-inputs="4"
          :should-auto-focus="true"
          input-type="letter-numeric"
          :conditionalClass="['one', 'two', 'three', 'four']"
          :placeholder="[' ', ' ', ' ', ' ']"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { axiosAPI } from "../../../axiosClient";
import { toast } from "vue3-toastify";
const otpStatus = ref(false);
export default {
  name: "CreatorForm3",
  props: ["brandId"],
  components: {},

  data() {
    return {
      pin: "",
    };
  },
  methods: {
    verifyPin() {
      if (otpStatus.value) {
        let FormData = {
          brand_id: this.brandId,
          otp: this.pin,
          step: 3,
        };
        let _this = this;
        axiosAPI
          .post(`v1/brands/web/signup`, FormData)
          .then((response) => {
            if (response.data && response.data.status) {
              this.$emit("submitForm", {
                status: true,
                brand_id: this.brandId,
              });
            } else {
              this.$emit("submitForm", {
                status: false,
              });
              if (this.$store.state.errorMessges) {
                toast.error(this.$store.state.errorMessges.invalid_otp);
              }
            }
          })
          .catch(function (error) {
            _this.$emit("submitForm", {
            status: false,
          });
            if (error.response && error.response.data) {
              for (const error_msg in error.response.data) {
                // this.FromErrorMessages[error_msg] = error.response.data[error_msg];
                this.FromErrorFlags[error_msg] = false;
              }
            }
          });
      } else {
        this.$emit("submitForm", {
                status: false,
              });
        toast.info(this.$store.state.errorMessges.enter_otp);
      }
    },
    handleOnComplete(data) {
      this.pin = data;
      otpStatus.value = true;
    },
    handleOnChange(data) {
      if (data.length != 4) otpStatus.value = false;
    },
  },
};
</script>

<style lang="scss">
.ts-sentyou-otp {
  max-width: 317px;
  margin-inline: auto;
}
.ts-otp-input {
  display: flex;
  justify-content: center;
}
.otp-input {
  width: 58px !important;
  height: 50px !important;
  padding: 5px;
  margin: 0 6px;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  text-align: center;
  background-color: #ebebeb;
  &::placeholder {
    content: "";
  }
  &:focus {
    border: 1px solid #c0c0c0;
    outline: none;
  }
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #ebebeb;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
