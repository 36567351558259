<template>
  <LoadingScreen v-if="!$store.state.brandFaqPage" />
  <div>
    <BrandFaqContent />
  </div>
</template>
<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import BrandFaqContent from "../components/Faq/BrandFaqContent.vue";

export default {
  name: "BrandFaq",
  components: {
    LoadingScreen,
    BrandFaqContent,
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getBrandFaqPage");
      }
    },
  },
  created() {
    this.$store.dispatch("getBrandFaqPage");
  },
};
</script>
