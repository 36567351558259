<template>
  <LoadingScreen v-if="!$store.state.TcPrivacy" />
  <div>
    <TcPrivacy />
  </div>
</template>
<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import TcPrivacy from "../components/Common/TcPrivacy.vue";

export default {
  name: "CreatorTc",
  components: {
    LoadingScreen,
    TcPrivacy,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getCreatorTc");
      }
    },
  },
  created() {
    this.$store.dispatch("getCreatorTc");
  },
};
</script>
