<template>
  <section class="ts-unlock-benifits" v-if="$store.state.homePage">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <h1
          class="ts-fs-1 text-center text-lg-start ts-text-gray-5 fw-bold mb-0"
        >
          {{ $store.state.homePage.AsACreator.Title.title }}
          <span class="ts-text-primary-1">
            {{ $store.state.homePage.AsACreator.Title.titleHighlighted }}</span
          >
        </h1>
        <h1
          class="ts-fs-1-ii text-center text-lg-start ts-text-gray-5 fw-normal mb-04 mb-lg-06"
        >
          {{ $store.state.homePage.AsACreator.Title.subtitle }}
        </h1>
        <p class="ts-fs-5 text-center text-lg-start fw-light mb-06 mb-lg-07-ii">
          {{ $store.state.homePage.AsACreator.description }}
        </p>
        <div
          class="d-none d-lg-flex justify-content-lg-start flex-column flex-sm-row gap-2"
        >
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-primary rounded-pill"
            :to="$store.state.homePage.AsACreator.SignUpButton.to"
          >
            {{ $store.state.homePage.AsACreator.SignUpButton.text }}
          </router-link>
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-outline-primary rounded-pill"
            :to="$store.state.homePage.AsACreator.LearnMoreButton.to"
          >
            {{ $store.state.homePage.AsACreator.LearnMoreButton.text }}
          </router-link>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          class="w-100 mb-06 mb-lg-0"
          :src="
            $store.state.baseUrl +
            $store.state.homePage.AsACreator.Image.data.attributes.url
          "
          :alt="
            $store.state.homePage.AsACreator.Image.data.attributes
              .alternativeText
          "
        />

        <div
          class="d-flex d-lg-none align-items-center justify-content-center justify-content-lg-start flex-column flex-sm-row gap-2"
        >
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-primary rounded-pill text-center"
            :to="$store.state.homePage.AsACreator.SignUpButton.to"
            >{{
              $store.state.homePage.AsACreator.SignUpButton.text
            }}</router-link
          >
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-outline-primary rounded-pill text-center"
            :to="$store.state.homePage.AsACreator.LearnMoreButton.to"
            >{{
              $store.state.homePage.AsACreator.LearnMoreButton.text
            }}</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "UnlockBenifits",
};
</script>
