import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_STRAPI_URL,
});

const axiosAPI = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

const axiosAdmin = axios.create({
  baseURL: process.env.VUE_APP_ADMIN_URL,
});

export { axiosClient, axiosAPI, axiosAdmin };
