<template>
  <LoadingScreen v-if="!$store.state.careerPage" />
  <div>
    <img
      v-if="$store.state.careerPage"
      class="ts-career-hero-img w-100 mb-08 mb-lg-10 d-none d-sm-block"
      :src="
        $store.state.baseUrl +
        $store.state.careerPage.Banner.largeImage.data.attributes.url
      "
      :alt="
        $store.state.careerPage.Banner.largeImage.data.attributes
          .alternativeText
      "
    />
    <img
      v-if="$store.state.careerPage"
      class="ts-career-hero-img w-100 mb-08 mb-lg-10 d-sm-none"
      :src="
        $store.state.baseUrl +
        $store.state.careerPage.Banner.smallImage.data.attributes.url
      "
      :alt="
        $store.state.careerPage.Banner.smallImage.data.attributes
          .alternativeText
      "
    />

    <section>
      <div
        class="ts-container-fluid container-fluid"
        v-if="$store.state.careerPage"
      >
        <header class="ts-career__header text-center mb-08 mb-lg-12">
          <h1
            class="ts-heading ts-fs-1 ts-text-gray-3 fw-bold text-center mb-04 mb-lg-07"
          >
            {{ $store.state.careerPage.Careers.title }}
          </h1>
          <p class="ts-desc ts-fs-5 fw-light mb-05 mb-lg-07">
            {{ $store.state.careerPage.Careers.description }}
          </p>
        </header>
      </div>

      <div class="ts-career-cards-group-wrapper">
        <div class="ts-container-fluid container-fluid">
          <div class="ts-career-cards-group">
            <div class="" v-for="card in $store.state.careers" :key="card.id">
              <JobCard
                :id="card.id"
                :title="card.attributes.title"
                :desc="card.attributes.description"
                :workType="card.attributes.workType"
                :jobType="card.attributes.jobType"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modal -->
    <div
      class="modal fade"
      id="applicationModal"
      tabindex="-1"
      aria-labelledby="applicationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered justify-content-center">
        <div class="modal-content">
          <div class="modal-body p-0">
            <ApplicationForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import JobCard from "../components/Career/JobCard.vue";
import ApplicationForm from "../components/Career/ApplicationForm.vue";

export default {
  name: "CareerPage",
  components: {
    LoadingScreen,
    JobCard,
    ApplicationForm,
  },

  data: function () {
    return {};
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },
  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getCareers");
        this.$store.dispatch("getCareerPage");
        this.$store.dispatch("getErrorMessages");
      }
    },
  },
  created() {
    this.$store.dispatch("getCareers");
    this.$store.dispatch("getCareerPage");
  },
};
</script>
<style lang="scss" scoped>
.ts-career-hero-img {
  @media (max-width: 991px) {
    min-height: 360px;
    object-fit: cover;
  }
}

.modal-content {
  background-color: transparent;
  border: 0;
  max-width: 476px;
  justify-content: center;
}

.ts-career__header {
  .ts-heading {
    max-width: 920px;
    margin-inline: auto;
  }

  .ts-desc {
    max-width: 990px;
    margin-inline: auto;
  }
}

.ts-career-cards-group {
  max-width: 1228px;
  margin: auto;

  &-wrapper {
    padding-block: 15px 90px;

    @media (min-width: 992px) {
      padding-block: 90px;
    }
    background-color: #fcfcfc;
  }

  @media (max-width: 991px) {
    & > div {
      border-bottom: 1px solid #e1e1e1;
    }
  }
  @media (min-width: 992px) {
    & > div {
      &:not(:last-child) {
        border-bottom: 1px solid #e1e1e1;
      }
    }
  }
}
</style>
