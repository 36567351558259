<template>
  <div>
    <header
      class="ts-contact-form-container__header ts-text-gray-4 text-center"
    >
      <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
        {{ $store.state.creatorSignupPage.Form2.title }}
      </h1>
      <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-05 mb-lg-11">
        {{ $store.state.creatorSignupPage.Form2.description }}
      </p>
    </header>
    <form>
      <div class="mb-2">
        <select
          class="form-select form-select-lg rounded-pill ts-phone"
          aria-label="Nationality"
          v-model="nationality"
          :class="{ 'is-invalid': FromErrorFlags.nationality }"
        >
          <option value="" selected disabled>Nationality</option>
          <option
            v-for="nationality in $store.state.nationalities"
            :value="nationality.id"
            :key="'creator-nationality-' + nationality.id"
          >
            {{ nationality.country_name }}
          </option>
        </select>
        <!-- <div class="invalid-feedback">
          {{ FromErrorMessages.nationality[0] }}
        </div> -->
      </div>
      <div class="ts-form-control-group d-flex mb-2">
        <select
          class="form-select form-select-lg rounded-pill ts-phone country-codes"
          aria-label="Default select example"
          v-model="country_code_mob"
          @change="
            checkMobileNumberExists(mobile_number, 'mobile');
            is_same_whatsapp = false;
          "
        >
          <option
            v-for="nationality in $store.state.nationalities"
            :value="nationality.code"
            :key="'mob-country-code-' + nationality.id"
            :data-text="nationality.country_name + '(' + nationality.code + ')'"
          >
            {{ nationality.country_name + "(" + nationality.code + ")" }}
          </option>
        </select>
        <div class="flex-fill">
          <input
            type="tel"
            class="ts-form-control-tel form-control form-control-lg rounded-pill"
            id="tsPhone"
            aria-describedby="PhoneHelp"
            :placeholder="$store.state.creatorSignupPage.Form2.phonePlaceholder"
            v-model="mobile_number"
            @change="
              checkMobileNumberExists(mobile_number, 'mobile');
              is_same_whatsapp = false;
            "
            ref="creatorMobileNumber"
            :class="{ 'is-invalid': FromErrorFlags.mobile_number }"
            autocomplete="off"
          />
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.alt_mobile[0] }}
          </div> -->
        </div>
      </div>

      <div class="px-3 mb-08 mt-2">
        <div class="form-check d-flex gap-2">
          <input
            class="ts-form-check-input form-check-input rounded-circle mb-1"
            type="checkbox"
            name="sameWhatsNumber"
            id="sameWhatsNumber"
            v-model="is_same_whatsapp"
            @change="copyMobileToWhatsapp($event)"
          />
          <label class="form-check-label" for="sameWhatsNumber">
            {{ $store.state.creatorSignupPage.Form2.useSameNumberWhatsappText }}
          </label>
        </div>
      </div>
      <div class="ts-form-control-group d-flex mb-2">
        <select
          class="form-select form-select-lg rounded-pill ts-phone country-codes"
          aria-label="Default select example"
          v-model="country_code_whats"
          ref="country_code_whats"
          :disabled="is_same_whatsapp"
          @change="
            checkMobileNumberExists(alt_mobile, 'whatsapp');
            is_same_whatsapp = false;
          "
        >
          <option
            v-for="nationality in $store.state.nationalities"
            :value="nationality.code"
            :key="'whatsapp-country-code-' + nationality.id"
            :data-text="nationality.country_name + '(' + nationality.code + ')'"
          >
            {{ nationality.country_name + "(" + nationality.code + ")" }}
          </option>
        </select>
        <div class="flex-fill">
          <input
            type="tel"
            class="ts-form-control-tel form-control form-control-lg rounded-pill"
            id="tsWhastapp Number"
            aria-describedby="Whastapp NumberHelp"
            :placeholder="
              $store.state.creatorSignupPage.Form2.whatsappPlaceholder
            "
            v-model="alt_mobile"
            @change="
              checkMobileNumberExists(alt_mobile, 'whatsapp');
              is_same_whatsapp = false;
            "
            :class="{ 'is-invalid': FromErrorFlags.alt_mobile }"
            :disabled="is_same_whatsapp"
            ref="creatorWhatsappNumber"
            autocomplete="off"
          />
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.mobile_number[0] }}
          </div> -->
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { axiosAPI } from "../../../axiosClient";
import { ref } from "vue";
import { toast } from "vue3-toastify";
const phoneCheckCount = ref(0);
export default {
  name: "CreatorForm2",
  props: ["creatorId"],
  data() {
    return {
      phoneCheckCount: phoneCheckCount,
      country_code_whats: "+971",
      alt_mobile: "",
      country_code_mob: "+971",
      mobile_number: "",
      nationality: "",
      is_same_whatsapp: false,
      deleted_reapply_confirmation: false,
      FromErrorFlags: {
        alt_mobile: false,
        mobile_number: false,
        nationality: false,
      },
      FromErrorMessages: {
        alt_mobile: [],
        mobile_number: [],
        nationality: [],
      },
      requestTimeout: "",
    };
  },
  watch: {
    mobile_number() {
      this.is_same_whatsapp = false;
      // clearTimeout(this.requestTimeout);
      // if (newValue.length > 3) {
      //   this.requestTimeout = setTimeout(() => {
      //     this.checkMobileNumberExists(newValue, "mobile");
      //   }, 1000);
      // }
      this.$refs.creatorMobileNumber.classList.remove("is-valid");
      this.$refs.creatorMobileNumber.classList.remove("is-invalid");
      // this.FromErrorFlags.mobile_number = false;
      this.FromErrorMessages.mobile_number = [];
    },
    alt_mobile() {
      // if (newValue.length > 3) {
      //     this.checkMobileNumberExists(newValue, "whatsapp");
      // }
      this.$refs.creatorWhatsappNumber.classList.remove("is-valid");
      this.$refs.creatorWhatsappNumber.classList.remove("is-invalid");
      // this.FromErrorFlags.alt_mobile = false;
      this.FromErrorMessages.alt_mobile = [];
    },
    nationality() {
      this.FromErrorFlags.nationality = false;
      this.FromErrorMessages.nationality = [];
    },
  },
  methods: {
    submitFrom2() {
      let errorFlag = false;
      if (this.mobile_number == "" || this.mobile_number.length < 4) {
        this.FromErrorFlags.mobile_number = true;
        errorFlag = true;
      }
      if (this.alt_mobile == "" || this.alt_mobile.length < 4) {
        this.FromErrorFlags.alt_mobile = true;
        errorFlag = true;
      }
      if (this.FromErrorFlags.mobile_number) {
        this.$refs.creatorMobileNumber.classList.add("is-invalid");
        errorFlag = true;
      }
      if (this.FromErrorFlags.alt_mobile) {
        this.$refs.creatorWhatsappNumber.classList.add("is-invalid");
        errorFlag = true;
      }
      if (this.nationality == "") {
        this.FromErrorFlags.nationality = true;
        this.FromErrorMessages.nationality.push("nationality_empty");
        errorFlag = true;
      } else {
        this.FromErrorFlags.nationality = false;
        this.FromErrorMessages.nationality.pop("nationality_empty");
      }
      if (
        errorFlag ||
        this.FromErrorFlags.nationality ||
        this.FromErrorFlags.alt_mobile ||
        this.FromErrorFlags.mobile_number ||
        phoneCheckCount.value > 0
      ) {
        this.$emit("submitForm", {
          status: false,
        });
        return false;
      }
      let FormData = {
        creator_id: this.creatorId,
        country_code_whats: this.country_code_whats,
        alt_mobile: this.alt_mobile,
        country_code_mob: this.country_code_mob,
        mobile_number: this.mobile_number,
        nationality: this.nationality,
        deleted_reapply_confirmation: this.deleted_reapply_confirmation,
        step: 2,
      };
      let _this = this;
      axiosAPI
        .post(`v1/creator/web/signup`, FormData)
        .then((response) => {
          this.deleted_reapply_confirmation = false;
          if (response.data && response.data.status) {
            this.$emit("submitForm", {
              status: true,
              creator_id: this.creatorId,
              instagramPin: response.data.instagramPin,
            });
          } else {
            if (response.data && response.data.deleted_account) {
              if (
                confirm(
                  "We've noticed a prior association with your profile. To enhance your experience, we are linking your request with the old association. Would you like to proceed?"
                )
              ) {
                this.deleted_reapply_confirmation = true;
                this.submitFrom2();
              } else {
                this.$emit("submitForm", {
                  status: false,
                  redirect: true,
                });
              }
            } else {
              this.$emit("submitForm", {
                status: false,
              });
              toast.error(
                this.$store.state.errorMessges[response.data.message]
              );
            }
          }
        })
        .catch(function (error) {
          _this.$emit("submitForm", {
            status: false,
          });
          if (error.response && error.response.data) {
            for (const error_msg in error.response.data) {
              _this.FromErrorMessages[error_msg] =
                error.response.data[error_msg];
              _this.FromErrorFlags[error_msg] = true;
            }
          }
        });
    },
    copyMobileToWhatsapp(event) {
      if (event.target.checked) {
        this.country_code_whats = this.country_code_mob;
        this.alt_mobile = this.mobile_number;
        this.checkMobileNumberExists(this.alt_mobile, "whatsapp");
      }
      this.$nextTick(function () {
        // item.options[item.selectedIndex].text = item.value;
        this.$refs.country_code_whats.options[
          this.$refs.country_code_whats.selectedIndex
        ].text = this.$refs.country_code_whats.value;
      });
    },
    checkMobileNumberExists(data, type) {
      phoneCheckCount.value++;
      let FormData = {
        mobile: data,
      };
      if (type == "mobile") {
        this.FromErrorFlags.mobile_number = false;
        this.FromErrorMessages.mobile_number = [];
        FormData["country_code"] = this.country_code_mob;
      } else {
        this.FromErrorFlags.alt_mobile = false;
        this.FromErrorMessages.alt_mobile = [];
        FormData["country_code"] = this.country_code_whats;
      }
      let _this = this;
      axiosAPI
        .post(`v1/creator/check-mobile`, FormData)
        .then((response) => {
          if (response.data && response.data.status) {
            if (type == "mobile") {
              this.$refs.creatorMobileNumber.classList.add("is-valid");
            } else {
              this.$refs.creatorWhatsappNumber.classList.add("is-valid");
            }
          } else {
            if (type == "mobile") {
              // this.$refs.creatorMobileNumber.classList.add('is-invalid');
              this.FromErrorFlags.mobile_number = true;
              if (this.$store.state.errorMessges) {
                toast.error(
                  this.$store.state.errorMessges[response.data.msg_slug]
                );
              }
              this.FromErrorMessages.mobile_number = response.data.msg_slug;
            } else {
              // this.$refs.creatorWhatsappNumber.classList.add('is-invalid');
              this.FromErrorFlags.alt_mobile = true;
              if (this.$store.state.errorMessges) {
                toast.error(
                  this.$store.state.errorMessges[response.data.msg_slug]
                );
              }
              this.FromErrorMessages.alt_mobile = response.data.msg_slug;
            }
          }
          phoneCheckCount.value--;
        })
        .catch(function () {
          if (type == "mobile") {
            // _this.$refs.creatorMobileNumber.classList.add('is-invalid');
            _this.FromErrorFlags.mobile_number = true;
          } else {
            // _this.$refs.creatorWhatsappNumber.classList.add('is-invalid');
            _this.FromErrorFlags.alt_mobile = true;
          }
          phoneCheckCount.value--;
        });
    },
  },
  mounted() {
    let app = this;
    [...document.querySelectorAll(".country-codes")].forEach(function (item) {
      if (item.selectedIndex > 0) {
        item.options[item.selectedIndex].text = item.value;
      }
      app.$nextTick(function () {
        setTimeout(() => {
          if (item.selectedIndex > 0) {
            item.options[item.selectedIndex].text = item.value;
          }
        }, 600);
      });
      setTimeout(() => {
        if (item.selectedIndex > 0) {
          item.options[item.selectedIndex].text = item.value;
        }
      }, 1000);
      item.addEventListener("change", function handleChange(event) {
        event.target.options[event.target.selectedIndex].text =
          event.target.value;
      });
      item.addEventListener("mousedown", function handleChange(event) {
        [...event.target.options].forEach((option) => {
          option.text = option.dataset.text;
        });
        event.target.options[event.target.selectedIndex].text =
          event.target.value;
      });
    });
  },
};
</script>
<style lang="scss" scoped>
.country-codes {
  width: 25%;
}
</style>
