<template>
  <LoadingScreen v-if="!$store.state.overviewPage" />
  <div>
    <section class="ts-overview-wrapper" v-if="$store.state.overviewPage">
      <transition appear @before-enter="beforeEnter" @enter="enter">
        <div class="ts-container-fluid container-fluid">
          <header class="ts-team__header text-center mb-07 mb-lg-14">
            <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-04">
              {{ $store.state.overviewPage.title }}
            </h1>
            <div class="ts-fs-5 fw-light mb-05 mb-lg-07">
              <p class="text-center">
                {{ $store.state.overviewPage.description }}
              </p>
            </div>
          </header>
          <!-- ===================== -->
          <!-- =======Computer====== -->
          <div class="ts-grid-main-container ts-computer ts-1600-down-none">
            <!-- Left -->
            <div class="ts-grid-main-container__1">
              <div class="ts-group-container-3 mb-03">
                <div class="ts-DetailedAnalytics">
                  <DetailedAnalytics />
                </div>
                <div class="ts-SaveWithAlist">
                  <SaveWithAlist />
                </div>
              </div>
              <div class="ts-group-container-4">
                <div class="ts-PrivateFeedback">
                  <PrivateFeedback />
                </div>
                <div>
                  <div class="ts-PublicReviews">
                    <PublicReviews />
                  </div>
                  <div class="ts-VettedBusinesses">
                    <VettedBusinesses />
                  </div>
                </div>
                <div class="ts-EfficientMatching">
                  <EfficientMatching />
                </div>
              </div>
            </div>
            <!-- Right -->
            <div class="ts-grid-main-container__2">
              <div class="ts-group-container-2">
                <div>
                  <div class="ts-group-container-3 mb-03">
                    <div class="ts-VettedCreators">
                      <VettedCreators />
                    </div>
                    <div class="ts-ContentUsage">
                      <ContentUsage />
                    </div>
                  </div>
                  <div class="ts-group-container-6">
                    <div class="ts-ContentModeration">
                      <ContentModeration />
                    </div>
                    <div class="ts-ContentGuidelines">
                      <ContentGuidelines />
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div class="ts-LiveSupport mb-3">
                      <LiveSupport />
                    </div>
                    <div class="ts-VoucherTracking">
                      <VoucherTracking />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ===================== -->
          <!-- =======Tablet====== -->
          <div class="ts-1600-up-none ts-tablet ts-992-down-none">
            <div class="ts-group-container-50-50 mb-03">
              <div class="ts-VettedCreators">
                <VettedCreators />
              </div>
              <div>
                <div class="ts-group-container-50-50">
                  <div class="ts-ContentUsage">
                    <ContentUsage />
                  </div>
                  <div>
                    <div class="ts-PublicReviews">
                      <PublicReviews />
                    </div>
                    <div class="ts-SaveWithAlist">
                      <SaveWithAlist />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ts-group-container-50-50">
              <div class="ts-group-container-50-50">
                <div class="ts-PrivateFeedback">
                  <PrivateFeedback />
                </div>
                <div class="ts-EfficientMatching">
                  <EfficientMatching />
                </div>
                <div class="ts-ContentGuidelines">
                  <ContentGuidelines />
                </div>
                <div class="ts-ContentModeration">
                  <ContentModeration />
                </div>
              </div>
              <div>
                <div class="mb-03">
                  <div class="ts-DetailedAnalytics">
                    <DetailedAnalytics />
                  </div>
                </div>
                <div class="ts-group-container-50-50">
                  <div class="ts-VoucherTracking">
                    <VoucherTracking />
                  </div>
                  <div>
                    <div class="ts-VettedBusinesses mb-3">
                      <VettedBusinesses />
                    </div>
                    <div class="ts-LiveSupport">
                      <LiveSupport />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ===================== -->
          <!-- ======= Mobile====== -->
          <div class="ts-992-up-none ts-mobile">
            <div class="ts-VettedCreators">
              <VettedCreators class="mb-03" />
            </div>
            <div class="ts-group-container-50-50 mb-03">
              <div class="ts-ContentUsage">
                <ContentUsage />
              </div>
              <div class="ts-EfficientMatching">
                <EfficientMatching />
              </div>
            </div>
            <div class="ts-DetailedAnalytics">
              <DetailedAnalytics class="mb-03" />
            </div>
            <div class="ts-group-container-50-50 mb-03">
              <div class="ts-PrivateFeedback">
                <PrivateFeedback />
              </div>
              <div>
                <div class="ts-PublicReviews">
                  <PublicReviews />
                </div>
                <div class="ts-SaveWithAlist">
                  <SaveWithAlist />
                </div>
              </div>
            </div>
            <div class="ts-group-container-50-50 mb-03">
              <div>
                <div class="mb-3">
                  <div class="ts-LiveSupport">
                    <LiveSupport />
                  </div>
                </div>
                <div class="ts-VettedBusinesses">
                  <VettedBusinesses />
                </div>
              </div>
              <div class="ts-VoucherTracking">
                <VoucherTracking />
              </div>
            </div>
            <div class="ts-ContentModeration">
              <ContentModeration class="mb-03" />
            </div>
            <div class="ts-ContentGuidelines">
              <ContentGuidelines />
            </div>
          </div>
        </div>
      </transition>
    </section>
  </div>
</template>
<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
// import CreatorFaqContent from "../components/Faq/CreatorFaqContent.vue";
import ContentGuidelines from "../components/Overview/ContentGuidelines.vue";
import ContentModeration from "../components/Overview/ContentModeration.vue";
import ContentUsage from "../components/Overview/ContentUsage.vue";
import DetailedAnalytics from "../components/Overview/DetailedAnalytics.vue";
import EfficientMatching from "../components/Overview/EfficientMatching.vue";
import LiveSupport from "../components/Overview/LiveSupport.vue";
import PrivateFeedback from "../components/Overview/PrivateFeedback.vue";
import PublicReviews from "../components/Overview/PublicReviews.vue";
import SaveWithAlist from "../components/Overview/SaveWithAlist.vue";
import VettedBusinesses from "../components/Overview/VettedBusinesses.vue";
import VettedCreators from "../components/Overview/VettedCreators.vue";
import VoucherTracking from "../components/Overview/VoucherTracking.vue";

import { gsap } from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "OverviewPage",

  components: {
    LoadingScreen,
    ContentGuidelines,
    ContentModeration,
    ContentUsage,
    EfficientMatching,
    LiveSupport,
    PrivateFeedback,
    PublicReviews,
    DetailedAnalytics,
    SaveWithAlist,
    VettedBusinesses,
    VettedCreators,
    VoucherTracking,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getOverviewPage");
        // this.animateSlideUp();
      }
    },
  },
  methods: {
    enter() {
      // Header
      gsap.from(".ts-team__header .ts-fs-1", {
        y: 50,
        opacity: 0,
        duration: 1,
      });
      gsap.from(".ts-team__header .ts-fs-5", {
        y: 50,
        opacity: 0,
        duration: 1,
        delay: 0.2,
      });

      // ====================================
      // ============ Computer ==============
      // ====================================
      gsap.from(".ts-computer .ts-DetailedAnalytics", {
        x: -50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-computer .ts-DetailedAnalytics",
      });
      gsap.from(".ts-computer .ts-SaveWithAlist", {
        y: -50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-computer .ts-SaveWithAlist",
      });
      gsap.from(".ts-computer .ts-PrivateFeedback", {
        x: -50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-computer .ts-PrivateFeedback",
      });
      gsap.from(".ts-computer .ts-PublicReviews", {
        y: 50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-computer .ts-PublicReviews",
      });
      gsap.from(".ts-computer .ts-VettedBusinesses", {
        y: 50,
        opacity: 0,
        duration: 0.7,
        delay: 0.2,
        scrollTrigger: ".ts-computer .ts-VettedBusinesses",
      });
      gsap.from(".ts-computer .ts-EfficientMatching", {
        y: 50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-computer .ts-EfficientMatching",
      });
      gsap.from(".ts-computer .ts-VettedCreators", {
        y: -50,
        opacity: 0,
        duration: 1,
        delay: 0.2,
        scrollTrigger: ".ts-computer .ts-VettedCreators",
      });
      gsap.from(".ts-computer .ts-ContentUsage", {
        y: -50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-computer .ts-ContentUsage",
      });
      gsap.from(".ts-computer .ts-ContentModeration", {
        y: 50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-computer .ts-ContentModeration",
      });
      gsap.from(".ts-computer .ts-ContentGuidelines", {
        y: 50,
        opacity: 0,
        duration: 0.7,
        delay: 0.1,
        scrollTrigger: ".ts-computer .ts-ContentGuidelines",
      });
      gsap.from(".ts-computer .ts-LiveSupport", {
        x: 50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-computer .ts-LiveSupport",
      });
      gsap.from(".ts-computer .ts-VoucherTracking", {
        x: 50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-computer .ts-VoucherTracking",
      });
      // ====================================
      // ============ Tablet ==============
      // ====================================
      gsap.from(".ts-tablet .ts-DetailedAnalytics", {
        x: 50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-tablet .ts-DetailedAnalytics",
      });
      gsap.from(".ts-tablet .ts-SaveWithAlist", {
        y: -50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-tablet .ts-SaveWithAlist",
      });
      gsap.from(".ts-tablet .ts-PrivateFeedback", {
        x: -50,
        opacity: 0,
        duration: 1,
        delay: 0.2,
        scrollTrigger: ".ts-tablet .ts-PrivateFeedback",
      });
      gsap.from(".ts-tablet .ts-PublicReviews", {
        y: -50,
        opacity: 0,
        duration: 0.7,
        delay: 0.2,
        scrollTrigger: ".ts-tablet .ts-PublicReviews",
      });
      gsap.from(".ts-tablet .ts-VettedBusinesses", {
        x: 50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-tablet .ts-VettedBusinesses",
      });
      gsap.from(".ts-tablet .ts-LiveSupport", {
        x: 50,
        opacity: 0,
        duration: 0.7,
        delay: 0.2,
        scrollTrigger: ".ts-tablet .ts-LiveSupport",
      });
      gsap.from(".ts-tablet .ts-EfficientMatching", {
        x: -50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-tablet .ts-EfficientMatching",
      });
      gsap.from(".ts-tablet .ts-VettedCreators", {
        y: -50,
        opacity: 0,
        duration: 1,
        delay: 0.2,
        scrollTrigger: ".ts-tablet .ts-VettedCreators",
      });
      gsap.from(".ts-tablet .ts-ContentUsage", {
        y: -50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-tablet .ts-ContentUsage",
      });
      gsap.from(".ts-tablet .ts-ContentModeration", {
        y: 50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-tablet .ts-ContentModeration",
      });
      gsap.from(".ts-tablet .ts-ContentGuidelines", {
        y: 50,
        opacity: 0,
        duration: 0.7,
        delay: 0.1,
        scrollTrigger: ".ts-tablet .ts-ContentGuidelines",
      });
      gsap.from(".ts-tablet .ts-VoucherTracking", {
        y: 50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-tablet .ts-VoucherTracking",
      });

      // ====================================
      // ============ Mobile ================
      // ====================================
      gsap.from(".ts-mobile .ts-VettedCreators", {
        x: -50,
        opacity: 0,
        duration: 1,
        delay: 0.2,
        scrollTrigger: ".ts-mobile .ts-VettedCreators",
      });
      gsap.from(".ts-mobile .ts-DetailedAnalytics", {
        x: 50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-mobile .ts-DetailedAnalytics",
      });
      gsap.from(".ts-mobile .ts-SaveWithAlist", {
        y: 50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-mobile .ts-SaveWithAlist",
      });
      gsap.from(".ts-mobile .ts-PrivateFeedback", {
        x: -50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-mobile .ts-PrivateFeedback",
      });
      gsap.from(".ts-mobile .ts-PublicReviews", {
        x: 50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-mobile .ts-PublicReviews",
      });
      gsap.from(".ts-mobile .ts-VettedBusinesses", {
        x: -50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-mobile .ts-VettedBusinesses",
      });
      gsap.from(".ts-mobile .ts-LiveSupport", {
        x: -50,
        opacity: 0,
        duration: 0.7,
        delay: 0.2,
        scrollTrigger: ".ts-mobile .ts-LiveSupport",
      });
      gsap.from(".ts-mobile .ts-EfficientMatching", {
        x: 50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-mobile .ts-EfficientMatching",
      });

      gsap.from(".ts-mobile .ts-ContentUsage", {
        x: -50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-mobile .ts-ContentUsage",
      });
      gsap.from(".ts-mobile .ts-ContentModeration", {
        y: 50,
        opacity: 0,
        duration: 0.7,
        scrollTrigger: ".ts-mobile .ts-ContentModeration",
      });
      gsap.from(".ts-mobile .ts-ContentGuidelines", {
        y: 50,
        opacity: 0,
        duration: 0.7,
        delay: 0.1,
        scrollTrigger: ".ts-mobile .ts-ContentGuidelines",
      });
      gsap.from(".ts-mobile .ts-VoucherTracking", {
        x: 50,
        opacity: 0,
        duration: 1,
        scrollTrigger: ".ts-mobile .ts-VoucherTracking",
      });
    },
  },
  created() {
    this.$store.dispatch("getOverviewPage").then(() => {
      // this.animateSlideUp();
    });
  },

  mounted() {
    // this.animateSlideUp();
    // gsap.from(".ts-team__header .ts-fs-1", {
    //   y: 50,
    //   opacity: 0,
    //   duration: 1,
    //
    // });
    // gsap.from(".ts-team__header .ts-fs-5", {
    //   y: 50,
    //   opacity: 0,
    //   duration: 1,
    //
    //   delay: 0.2,
    // });
  },
};
</script>

<style lang="scss">
.ts-overview-wrapper {
  margin-block: 170px 150px;
  @media (max-width: 1600px) {
    margin-block: 150px 120px;
  }
  @media (max-width: 991px) {
    margin-block: 150px 98px;
  }

  .ts-container-fluid {
    max-width: 1699px;
  }

  // .ts-card-ratio {
  //   &-1 {
  //     aspect-ratio: 156 / 77;
  //   }
  // }

  .ts-grid-main-container {
    display: grid;
    //   grid-template-columns: 44% 56%;
    grid-template-columns: 44fr 56fr;
    gap: 0.75rem;

    @media (max-width: 1600px) {
      grid-template-columns: 1fr;
      &__1 {
        order: 2;
      }
      &__2 {
        order: 1;
      }
    }
  }
  .ts-overview-wrapper {
    margin-block: 16.5rem 15.625rem;
  }

  .ts-group-container {
    &-2,
    &-3,
    &-4,
    &-5,
    &-6,
    &-50-50 {
      display: grid;
      gap: 0.75rem;
    }
    &-2 {
      grid-template-columns: 3fr 1fr;
    }
    &-3 {
      grid-template-columns: 2fr 1fr;
    }
    &-4 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &-5,
    &-6 {
      grid-template-columns: 1fr 1fr;
    }
    &-50-50 {
      grid-template-columns: 1fr 1fr;
    }
  }
  .ts-overview {
    &-main-container {
      display: grid;
      grid-template-columns: 44fr 56fr;

      // gap: 0.75rem;
    }
    &-card {
      $self: &; // This is important.
      background-color: #f4f4f4;
      color: #222121;
      padding: 0.875rem;
      border-radius: 18px;
      min-height: 230px;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 100%;
      background-position: center;
      background-size: cover;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.14);

      @media (max-width: 1600px) {
        min-height: 215px;
      }
      @media (max-width: 991px) {
        min-height: 197px;
      }
      &--sm {
        min-height: 201px;
      }
      &--lg {
        min-height: 473px;
        @media (max-width: 1600px) {
          min-height: 446px;
        }
        @media (max-width: 991px) {
          min-height: 410px;
        }
      }
      &__1 {
        .ts-overview-card__content {
          max-width: 208px;
        }
      }
    }
  }
  @media (max-width: 1600px) {
    .ts-1600-down-none {
      display: none;
    }
    // .ts-overview-card-vattedBusiness {
    //   margin-bottom: 0.75rem;
    // }
    @media (min-width: 991px) {
      .ts-overview-card--PublicReviews {
        min-height: 219px;
      }
    }
  }
  @media (min-width: 1601px) {
    .ts-1600-up-none {
      display: none;
    }
    // .ts-overview-card-livesupport {
    //   margin-bottom: 0.75rem;
    // }
  }
  @media (max-width: 991px) {
    .ts-992-down-none {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .ts-992-up-none {
      display: none;
    }
  }
}
</style>
