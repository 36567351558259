<template>
  <div class="ts-overview-card">
    <div class="ts-overview-card__content text-center">
      <img
        class="ts-bg-2 mx-auto mb-03"
        :src="
          $store.state.baseUrl +
          $store.state.overviewPage.ContentModeration.Image.data.attributes.url
        "
        alt="alternativeText"
      />
      <h1 class="ts-fs-6-ii mb-0 fw-semibold text-center">
        {{ $store.state.overviewPage.ContentModeration.title }}
      </h1>
      <p class="ts-fs-7-ii mb-0 text-center">
        {{ $store.state.overviewPage.ContentModeration.description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContentModeration",
};
</script>
