<template>
  <div class="ts-spinner-wrapper">
    <div class="spinner-border custom-spinner" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
};
</script>

<style scoped lang="scss">
.ts-spinner-wrapper {
  min-height: 100vh;
  display: grid;
  place-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10000;
}

.custom-spinner {
  color: #00a4b6;
}
</style>
