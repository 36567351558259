<template>
  <div
    class="ts-overview-card ts-overview-card__1 ts-card-ratio-1"
    :style="{
      backgroundImage:
        'url(' +
        $store.state.baseUrl +
        $store.state.overviewPage.DetailedAnalytics.backgroundImage.data
          .attributes.url +
        ')',
    }"
  >
    <div class="ts-overview-card__content me-auto">
      <h1 class="ts-fs-6-ii mb-0 fw-semibold fw-semibold mb-1">
        {{ $store.state.overviewPage.DetailedAnalytics.title }}
      </h1>
      <p class="ts-fs-7-ii mb-0">
        {{ $store.state.overviewPage.DetailedAnalytics.description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "DetailedAnalytics",
};
</script>
