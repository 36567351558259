<template>
  <div
    class="ts-wrapper--inner d-flex align-items-center justify-content-center h-auto"
  >
    <div class="w-100">
      <LoadingScreen v-if="isLoading" />
      <div
        id="calendly-inline-widget"
        class="calendly-inline-widget"
        data-url="https://calendly.com/alist-team/demo?hide_gdpr_banner=1&text_color=323232&primary_color=00a4b6"
      ></div>
      <!-- style="min-width: 320px; height: 700px" -->
    </div>
  </div>
</template>

<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import { ref, onMounted } from "vue";

export default {
  name: "BrandsDemoRequest",
  components: {
    LoadingScreen,
  },
  setup() {
    const isLoading = ref(true);

    onMounted(() => {
      const script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://assets.calendly.com/assets/external/widget.js"
      );
      script.onload = () => {
        // Introduce a delay before hiding the loading screen (e.g., 2 seconds)
        setTimeout(() => {
          isLoading.value = false;
        }, 1000);
      };

      document.head.appendChild(script);
    });

    return { isLoading };
  },
};
</script>

<style lang="scss" scoped>
.ts-wrapper--inner {
  min-height: calc(100vh - 77px);
  padding-top: 60px;
  padding-bottom: 40px;
}

.calendly-inline-widget {
  width: 100%;
  height: 1000px;
  // @media (min-width: 992px) {
  //   height: 500px;
  // }
  height: calc(100vh - 100px);
  // @media (min-width: 340px) {
  // height: 950px;
  // height: auto;
  // }
  @media (min-width: 666px) {
    height: 1080px;
  }
  @media (min-width: 1016px) {
    height: 670px;
  }
  @media (max-width: 649px) {
    /* Adjust styles if necessary */
  }
}
</style>
