<template>
  <div class="container">
    <section class="ts-ts-privacy-wrapper" v-if="$store.state.TcPrivacy">
      <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-5">
        {{ $store.state.TcPrivacy.Title }}
      </h1>

      <div
        v-for="(TcPrivacy, index) in $store.state.TcPrivacy.TermsAndConditions"
        :key="TcPrivacy.id"
      >
        <h2
          class="ts-fs-4 ts-text-gray-5 fw-bold mb-04 mb-lg-06"
          v-if="TcPrivacy.Title"
        >
          <span class="ts-text-gray-5">{{ index + 1 }} . </span>
          {{ TcPrivacy.Title }}
        </h2>
        <VueMarkdown
          v-if="TcPrivacy.description"
          class="ts-blog-desc fw-regular mb-05 mb-lg-07"
          :source="TcPrivacy.description"
        />
      </div>
    </section>
  </div>
</template>
<script>
import VueMarkdown from "markdown-vue";
export default {
  name: "TcPrivacy",

  components: {
    VueMarkdown,
  },
};
</script>
<style lang="scss" scoped>
.ts-ts-privacy-wrapper {
  margin-block: 6rem 3rem;
}
</style>
