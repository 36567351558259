<template>
  <LoadingScreen v-if="!$store.state.brandSignupPage" />
  <div>
    <BrandSignupHero />
  </div>
</template>
<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import BrandSignupHero from "../components/Form/BrandSignupHero.vue";

export default {
  name: "BrandsSignup",
  components: {
    LoadingScreen,
    BrandSignupHero,
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },
  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getBrandSignupPage");
      }
    },
  },
  created() {
    this.$store.dispatch("getBrandSignupPage");
  },
};
</script>
