<template>
  <LoadingScreen v-if="!$store.state.contactUsPage" />
  <div>
    <section class="ts-contact ts-contact--custom">
      <div class="ts-bg-radius ts-bg-radius--secondary z-minus-1"></div>
      <div
        class="ts-bg-radius ts-bg-radius--secondary ts-bg-radius--secondary--2 z-minus-1"
      ></div>
      <div
        class="ts-bg-radius ts-bg-radius--primary-secondary--mix z-minus-1"
      ></div>
      <img
        class="ts-bg"
        src="/images/Contact/contact-form-bg.svg"
        alt="alternativeText"
      />
      <div class="ts-contact__content" v-if="$store.state.contactUsPage">
        <div class="ts-container-fluid container-fluid">
          <div class="ts-contact-form-container ts-shadow-1">
            <div v-if="!isFormSubmitted">
              <header
                class="ts-contact-form-container__header text-center"
                v-if="$store.state.contactUsPage"
              >
                <h1
                  class="ts-fs-4-ii ts-text-primary-1 fw-bold text-center mb-02 mb-lg-04"
                >
                  {{ $store.state.contactUsPage.title }}
                </h1>
                <p class="ts-fs-7 fw-light mb-05 mb-lg-07">
                  {{ $store.state.contactUsPage.description }}
                </p>
              </header>
              <form v-on:submit.prevent="postContact">
                <div class="row row-cols-2">
                  <div class="mb-2 pe-1">
                    <!--  -->
                    <input
                      type="text"
                      class="form-control form-control-lg rounded-pill"
                      id="firstName"
                      :placeholder="
                        $store.state.contactUsPage.FormPlaceholder.firstName
                      "
                      v-model="firstName"
                      minlength="2"
                      required
                    />
                  </div>
                  <div class="mb-2 ps-1">
                    <input
                      type="text"
                      class="form-control form-control-lg rounded-pill"
                      id="lastName"
                      :placeholder="
                        $store.state.contactUsPage.FormPlaceholder.lastName
                      "
                      v-model="lastName"
                      minlength="2"
                      required
                    />
                  </div>
                </div>

                <div class="mb-2">
                  <input
                    type="email"
                    class="form-control form-control-lg rounded-pill"
                    id="email"
                    aria-describedby="emailHelp"
                    :placeholder="
                      $store.state.contactUsPage.FormPlaceholder.email
                    "
                    v-model="email"
                    required
                  />
                </div>
                <div class="mb-2">
                  <select
                    class="form-select form-select-lg rounded-pill"
                    aria-label="Default select example"
                    v-model="company"
                  >
                    <option
                      v-for="company in $store.state.contactUsPage
                        .FormPlaceholder.Companies"
                      :value="company.company"
                      :key="company.id"
                    >
                      {{ company.company }}
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <textarea
                    class="ts-message-box ts-textarea form-control form-control-lg"
                    id="yourMessage"
                    :placeholder="
                      $store.state.contactUsPage.FormPlaceholder.message
                    "
                    v-model="message"
                    minlength="20"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-secondary w-100 rounded-pill g-recaptcha text-center"
                  v-if="$store.state.contactUsPage"
                >
                  {{ $store.state.contactUsPage.btnSubmit }}
                </button>
              </form>
            </div>
            <Congratulation v-else>
              <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
                {{ $store.state.contactUsPage.Success.title }}
              </h1>
              <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-08">
                {{ $store.state.contactUsPage.Success.description }}
              </p>
              <router-link
                class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-secondary w-100 rounded-pill"
                :to="'/' + 'home'"
              >
                {{ $store.state.contactUsPage.Success.btnText }}
              </router-link>
            </Congratulation>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import axiosClient from "../axiosClient";

import LoadingScreen from "../components/Common/LoadingScreen.vue";
import { axiosClient } from "../axiosClient";
import Congratulation from "../components/Form/common/Congratulation.vue";
import { toast } from "vue3-toastify";
export default {
  name: "ContactPage",
  components: {
    LoadingScreen,
    Congratulation,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      company: "I'm brand",
      message: null,
      isFormSubmitted: false,
    };
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
    contactUsPage() {
      return this.$store.getters.contactUsPage;
    },
  },
  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getContactUsPage");
        this.$store.dispatch("getErrorMessages");
      }
    },
    contactUsPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.company =
          this.$store.state.contactUsPage.FormPlaceholder.Companies[0].company;
      }
    },
  },
  created() {
    this.$store.dispatch("getContactUsPage");
    this.$store.dispatch("getErrorMessages");
  },
  methods: {
    postContact() {
      axiosClient
        .post(`api/contact-uses`, {
          data: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            company: this.company,
            message: this.message,
          },
        })
        .then((response) => {
          // Handle a successful response here
          console.log(response);
          this.isFormSubmitted = true;
        })
        .catch((error) => {
          // Handle errors here
          console.error(error);
          if (error.response.status === 429) {
            if (this.$store.state.errorMessges) {
              toast.error(this.$store.state.errorMessges.spam_messages_error);
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
.ts-message-box {
  min-height: 15.1875rem !important;
  @media (max-width: 991px) {
    min-height: 15.75rem !important;
  }
}

.ts-contact {
  position: relative !important;
  padding: 6rem auto 5rem;

  &--custom {
    min-height: 100vh;
    display: grid;
    place-items: center;

    .ts-contact__content {
      margin-top: 77px;
    }
  }
  @media (max-width: 991px) {
    margin: 6.8125rem auto 3.5rem;
  }
  &__content {
    position: relative;
    z-index: 2;
  }

  &-form-container__header {
    max-width: 400px;
    margin: auto;
  }

  .ts-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(800px, 80vw, 900px);
  }

  &-form-container {
    background-color: white;
    max-width: 476px;
    border-radius: 28px;
    margin: auto;
    padding: 2.6875rem 1.5rem 1.875rem;

    @media (max-width: 991px) {
      padding: 3rem 1.375rem 1.625rem;
    }
  }

  .form-control,
  .form-select {
    background-color: #ebebeb;
    -webkit-text-fill-color: #646464 !important;
    padding-inline: 1.25rem !important;

    &:focus {
      border-color: #00a4b6 !important;
      box-shadow: none !important;
    }
  }

  .ts-textarea {
    border-radius: 1.25rem;
  }
}

form input:-webkit-autofill,
form input:-webkit-autofill:hover,
form input:-webkit-autofill:focus,
form input:-webkit-autofill:active {
  /* change backgournd  color */
  -webkit-box-shadow: 0 0 0 30px #ebebeb inset !important;
  -webkit-text-fill-color: #646464;
  /* background remain same */
  transition: background-color 500000s ease-in-out 0s;
  -webkit-text-fill-color: #646464 !important;
}

.ts-bg-radius--secondary {
  position: absolute;
  left: 53%;
  top: 104%;
  transform: translate(-50%, -50%);
  width: 716px !important;
  height: 716px !important;
  padding-top: 716px !important;
  z-index: -2;
}

.ts-bg-radius--secondary--2 {
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translate(-75%, -50%);
  width: 716px !important;
  height: 716px !important;
  padding-top: 716px !important;
  z-index: -4;
}
.ts-bg-radius--primary-secondary--mix {
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translate(-75%, -50%);
  width: 500px !important;
  height: 500px !important;
  padding-top: 500px !important;
  z-index: -1;
}

@media (max-width: 991px) {
  .ts-bg-radius--secondary {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 120% !important;
    height: 100% !important;
    padding-top: auto !important;
    z-index: -2;
  }

  .ts-bg-radius--primary-secondary--mix {
    position: absolute;
    left: 0;
    top: 50%;
    // transform: translate(-20%, -30%);
    transform: translate(20%, -20%);
    width: 60% !important;
    height: 60% !important;
    padding-top: 60% !important;
    z-index: -1;
  }
}
</style>
