<template>
  <div
    class="ts-overview-card ts-overview-card--lg"
    :style="{
      backgroundImage:
        'url(' +
        $store.state.baseUrl +
        $store.state.overviewPage.VoucherTracking.backgroundImage.data
          .attributes.url +
        ')',
    }"
  >
    <div class="ts-overview-card__content text-center">
      <img
        width="125"
        class="mw-100 ts-bg-2 mx-auto mb-01"
        :src="
          $store.state.baseUrl +
          $store.state.overviewPage.VoucherTracking.Image.data.attributes.url
        "
        alt="alternativeText"
      />
      <h1 class="ts-fs-6-ii mb-0 fw-semibold text-center">
        {{ $store.state.overviewPage.VoucherTracking.title }}
      </h1>
      <p class="ts-fs-7-ii mb-0 text-center">
        {{ $store.state.overviewPage.VoucherTracking.description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "VoucherTracking",
};
</script>
