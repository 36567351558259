<template>
  <section class="ts-faq">
    <div class="ts-faq__content">
      <div class="ts-container-fluid container-fluid">
        <h1
          class="ts-faq__title ts-fs-1 ts-text-gray-5 fw-bold text-center"
          v-if="$store.state.brandFaqPage"
        >
          {{ $store.state.brandFaqPage.Title.titleMain }}
          <span class="ts-text-primary-1">
            {{ $store.state.brandFaqPage.Title.titleHighlight }}
          </span>
        </h1>

        <BrandFaqMainContent />
      </div>
    </div>
  </section>
</template>

<script>
import BrandFaqMainContent from "./BrandFaqMainContent";
export default {
  name: "FaqSection",
  components: {
    BrandFaqMainContent,
  },
};
</script>

<style scoped lang="scss">
.ts-faq {
  background-color: #fcfcfc;
  min-height: calc(100vh - 216px);
  &__content {
    max-width: 1163px;
    margin: auto;
    padding: clamp(120px, 13vw, 216px) 0 clamp(60px, 7vw, 90px) !important;
  }

  &__title {
    margin-bottom: clamp(4.6875rem, 10vw, 110px);
  }
}
</style>
