<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center gap-sm-3">
      <li
        v-if="
          $store.state.blogPagination.page !== 1 &&
          $store.state.blogPagination.pageCount !== 0
        "
        class="page-item d-flex align-items-center"
        style="transform: rotate(180deg)"
        @click="backPage"
      >
        <button class="page-link d-flex align-items-center">
          <Icon name="chevron-right" />
        </button>
      </li>
      <li
        v-for="index in $store.state.blogPagination.pageCount"
        :key="index"
        class="page-item"
        @click="() => goToPage(index)"
      >
        <button
          class="page-link border-0"
          v-if="
            $store.state.blogPagination.pageSize <
            $store.state.blogPagination.total
          "
          :class="index === $store.state.blogPagination.page ? 'active' : ''"
        >
          {{ index }}
        </button>
      </li>
      <li
        v-if="
          $store.state.blogPagination.page !==
            $store.state.blogPagination.pageCount &&
          $store.state.blogPagination.pageCount !== 0
        "
        class="page-item d-flex align-items-center"
        @click="nextPage"
      >
        <button class="page-link d-flex align-items-center">
          <Icon name="chevron-right" />
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import Icon from "../../assets/icons/index.vue";
export default {
  name: "BlogPagination",
  components: {
    Icon,
  },

  methods: {
    goToPage(page) {
      this.callBlogs(page);
    },
    backPage() {
      this.callBlogs(this.$store.state.blogPagination.page - 1);
    },
    nextPage() {
      this.callBlogs(this.$store.state.blogPagination.page + 1);
    },
    callBlogs(page) {
      this.$store.dispatch("getBlogs", {
        topicId: this.$store.state.activeBlogId,
        page,
      });
    },
  },
};
</script>

<style lang="scss">
.pagination {
  .page-link {
    border: 0;
    color: #4d4d4d;
    font-weight: bold;
    line-height: 100%;
    padding-block: 0;
    font-size: clamp(19px, 3.3vw, 22px);
    background-color: transparent !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}

.page-item-back {
  svg {
    transform: rotate(180deg);
  }
}

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: #00a4b6;
  background-color: transparent;
  border-color: none;
}
</style>
