<template>
  <div
    class="ts-congratulation d-flex align-items-center justify-content-center h-100"
  >
    <div class="text-center ts-congratulation__content">
      <div class="me-4">
        <img
          class="mb-5"
          height="134"
          src="/images/congratulation.svg"
          alt="alternativeText"
        />
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "congratulationSection",
  //   data() {
  //     return {};
  //   },
};
</script>
