<template>
  <TsNavbar
    :NavBarCustomClasses="NavBarCustomClasses"
    @updateLang="updateLanguage"
  />
  <main class="ts-wrapper">
    <RouterView @data-navBarCustomClasses="receiveDataFromChild" />
  </main>
  <TsFooter />
</template>

<script>
import TsNavbar from "./TsNavbar.vue";
import TsFooter from "./TsFooter.vue";
export default {
  name: "App",
  components: {
    TsNavbar,
    TsFooter,
  },

  data() {
    return {
      NavBarCustomClasses: "",
    };
  },
  methods: {
    receiveDataFromChild(data) {
      this.NavBarCustomClasses = data;
    },
  }
};
</script>
