import { createApp } from "vue";
import App from "./App.vue";
import VOtpInput from "vue3-otp-input";
import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import router from "./router/index.js";
import store from "./store/index";

// Conditionally import Bootstrap styles based on the active language
const importBootstrapStyles = async () => {
  if (store.state.isRtl) {
    await import("bootstrap/dist/css/bootstrap.rtl.min.css");
  } else {
    await import("bootstrap/dist/css/bootstrap.min.css");
  }
};

// Import Bootstrap JavaScript bundle
const importBootstrapJS = async () => {
  await import("bootstrap/dist/js/bootstrap.bundle.js");
};

const initializeApp = async () => {
  await importBootstrapStyles();
  await importBootstrapJS();

  createApp(App)
    .component("v-otp-input", VOtpInput)
    .use(router)
    .use(Vue3Toasity, {
      autoClose: 3000,
      // ...
    })
    .use(store)
    .mount("#app");
};

// Initialize the app after dynamic imports
initializeApp();
