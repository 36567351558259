<template>
  <LoadingScreen
    v-if="
      !(
        $store.state.subscriptionPage &&
        $store.state.subscriptionPlans &&
        $store.state.subscriptionDurations
      )
    "
  />
  <div class="ts-subscription-wrapper" v-else>
    <Packages />
    <AddOn />
    <PlansOverview />
  </div>
</template>

<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import Packages from "@/components/Subscriptions/PackagesGroup.vue";
import AddOn from "@/components/Subscriptions/AddOn.vue";
import PlansOverview from "@/components/Subscriptions/PlansOverview.vue";

export default {
  name: "NotFound",
  components: {
    Packages,
    AddOn,
    PlansOverview,
    LoadingScreen,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("subscriptionsPage");
        this.$store.dispatch("subscriptionsPlans");
        this.$store.dispatch("subscriptionsDurations");
      }
    },
  },
  created() {
    this.$store.dispatch("subscriptionsPage");
    this.$store.dispatch("subscriptionsPlans");
    this.$store.dispatch("subscriptionsDurations");
  },
};
</script>

<style lang="scss" scoped>
.ts-subscription-wrapper {
  margin-top: clamp(150px, 10vw, 214px);
}
</style>
