<template>
  <div>
    <header
      class="ts-contact-form-container__header ts-text-gray-4 text-center"
    >
      <h1 class="ts-fs-4-1 fw-bold text-center mb-01">
        {{ $store.state.creatorSignupPage.Form4.title }}
      </h1>
      <p class="ts-fs-7 fw-light mb-05 mb-lg-10">
        {{ $store.state.creatorSignupPage.Form4.description }}
      </p>
    </header>
    <form>
      <div class="ts-social-media-handle-cards-group mb-5">
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
          @click="$emit('addSocialMedia', 'instagram')"
        >
          <div
            class="ts-social-media-handle"
            :class="{
              'ts-social-media-handle--active':
                formFourData.instagram_url != '',
            }"
          >
            <Icon name="instagram" />
          </div>
          <p class="mb-0">
            {{ $store.state.creatorSignupPage.Form4.instagramTitle }}
          </p>
        </div>
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
          @click="$emit('addSocialMedia', 'tiktok')"
        >
          <div
            class="ts-social-media-handle"
            :class="{
              'ts-social-media-handle--active': formFourData.tiktok != '',
            }"
          >
            <Icon name="tiktok" />
          </div>
          <p class="mb-0">
            {{ $store.state.creatorSignupPage.Form4.tiktokTitle }}
          </p>
        </div>
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
          @click="$emit('addSocialMedia', 'snapchat')"
        >
          <div class="ts-social-media-handle">
            <Icon name="snapchat" />
          </div>
          <p class="mb-0">
            {{ $store.state.creatorSignupPage.Form4.snapchatTitle }}
          </p>
        </div>
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
          @click="$emit('addSocialMedia', 'twitch')"
        >
          <div class="ts-social-media-handle">
            <Icon name="twitch" />
          </div>
          <p class="mb-0">
            {{ $store.state.creatorSignupPage.Form4.twitchTitle }}
          </p>
        </div>
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
          @click="$emit('addSocialMedia', 'youtube')"
        >
          <div class="ts-social-media-handle">
            <Icon name="youtube" />
          </div>
          <p class="mb-0">
            {{ $store.state.creatorSignupPage.Form4.youtubeTitle }}
          </p>
        </div>
        <div
          class="ts-social-media-handle-group"
          data-bs-toggle="modal"
          data-bs-target="#addSocialMedia"
          @click="$emit('addSocialMedia', 'twitter')"
        >
          <div class="ts-social-media-handle">
            <Icon name="twitter" />
          </div>
          <p class="mb-0">
            {{ $store.state.creatorSignupPage.Form4.twitterTitle }}
          </p>
        </div>
      </div>
      <div class="px-1 px-sm-3 mb-05">
        <div
          class="form-check d-flex justify-content-center justify-content-sm-start gap-2"
        >
          <input
            class="ts-form-check-input form-check-input rounded-circle mb-1"
            type="checkbox"
            name="sameWhatsNumber"
            id="sameWhatsNumber"
            v-model="agree_terms"
          />
          <label class="ts-fs-7-ii form-check-label" for="sameWhatsNumber">
            {{ $store.state.creatorSignupPage.Form4.AcceptTCLink.textMain }}
            <router-link
              class="ts-text-primary-1"
              target="_blank"
              :to="'/' + $store.state.creatorSignupPage.Form4.AcceptTCLink.Link"
            >
              {{ $store.state.creatorSignupPage.Form4.AcceptTCLink.textLink }}
            </router-link>
          </label>
          <!-- <div class="invalid-feedback"></div> -->
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Icon from "../../../assets/icons/index.vue";
import { axiosAPI } from "../../../axiosClient";
import { toast } from "vue3-toastify";
export default {
  name: "CreatorForm4",
  props: ["creatorId", "formFourData"],
  components: {
    Icon,
  },

  data() {
    return {
      agree_terms: false,
    };
  },
  methods: {
    submitFrom4() {
      if (this.formFourData.instagram_url.trim() == "") {
        this.$emit("submitForm", {
          status: false,
        });
        if (this.$store.state.errorMessges) {
          toast.info(
            this.$store.state.errorMessges.add_one_social_media_account
          );
        }
      } else {
        if (this.agree_terms) {
          let FormData = {
            creator_id: this.creatorId,
            instagram_url: this.formFourData.instagram_url,
            tiktok: this.formFourData.tiktok,
            step: 4,
          };
          let _this = this;
          axiosAPI
            .post(`v1/creator/web/signup`, FormData)
            .then((response) => {
              if (response.data && response.data.status) {
                this.$emit("submitForm", {
                  status: true,
                  creator_id: this.creatorId,
                });
              } else {
                this.$emit("submitForm", {
                  status: false,
                });
              }
            })
            .catch(function (error) {
              _this.$emit("submitForm", {
                status: false,
              });
              if (error.response && error.response.data) {
                for (const error_msg in error.response.data) {
                  // _this.FromErrorMessages[error_msg] =
                  //   error.response.data[error_msg];
                  _this.FromErrorFlags[error_msg] = true;
                }
              }
            });
        } else {
          this.$emit("submitForm", {
            status: false,
          });
          if (this.$store.state.errorMessges) {
            toast.info(this.$store.state.errorMessges.accept_tc);
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.ts-social-media-handle-cards-group {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  gap: 25px 34px;
  justify-content: center;
  max-width: 278px;
  margin: auto;
  // flex-row-gap: 35px;
}

.ts-social-media-handle {
  width: 68px;
  height: 65px;
  background-color: #f6f6f6;
  display: grid;
  place-items: center;
  border-radius: 12px;
  margin-bottom: 10px;
  cursor: pointer;

  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  svg {
    ellipse,
    path {
      fill: #777777 !important;
    }
  }

  #twitter {
    width: 33px !important;
    height: 27px !important;
  }

  #instagram {
    width: 34px !important;
    height: 34px !important;
  }

  &--active {
    background-color: #00a4b6;

    svg {
      ellipse,
      path {
        fill: white !important;
      }
    }
  }

  &-group {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 15px;
    color: #777777;
  }
}
</style>
