<template>
  <div class="ts-news-card">
    <router-link class="btn-link gap-2" :to="'/blog-post/' + id">
      <div class="ts-news-card__header ts-rounded-06 mb-05">
        <img
          class="ts-news-card__img ts-rounded-06"
          :src="$store.state.baseUrl + imageUrl"
          alt="alternativeText"
        />
      </div>
      <p class="d-none d-lg-block ts-text-primary-1 ts-fs-7 fw-light mb-03">
        {{ date }}
      </p>
      <h5
        class="ts-fs-5 ts-line-2-trauncate ts-text-gray-3 fw-regular mb-04 mb-lg-05"
      >
        {{ title }}
      </h5>
      <div
        class="d-flex ts-rtl-row-reverse d-lg-none justify-content-between mb-03"
      >
        <p class="ts-text-gray-7 mb-0">By: {{ generatedBy }}</p>
        <p class="ts-text-gray-7 mb-0">{{ date }}</p>
      </div>
    </router-link>
    <router-link
      class="btn d-flex ts-rtl-row-reverse ts-rtl-justify-content-end justify-content-lg-start align-items-center border-0 p-0 gap-2"
      :to="'/blog-post/' + id"
    >
      <span class="ts-text-gray-7"> {{ readMore }} </span>
      <span class="ts-icon">
        <Icon name="arrow-down" />
      </span>
    </router-link>
  </div>
</template>

<script>
import Icon from "../../assets/icons/index.vue";
export default {
  components: {
    Icon,
  },
  props: {
    title: String,
    imageUrl: String,
    date: String,
    generatedBy: String,
    id: String,
    readMore: String,
  },
};
</script>

<style lang="scss">
.ts-news-card {
  &__header,
  &__img {
    width: 100%;
    height: clamp(194px, 20vw, 268px);
    object-fit: cover;
  }
  &__header {
    background-color: #f2f2f2;
  }
  .btn-link {
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    @media (max-width: 991px) {
      span {
        color: #00a4b6 !important;
      }
      .ts-icon {
        transform: rotate(-90deg);

        svg {
          fill: #00a4b6 !important;
          stroke: #00a4b6 !important;

          path {
            stroke: #00a4b6 !important;
          }
        }
      }
    }
  }
  .btn {
    color: #707070 !important;
    font-size: 1.1875rem;

    @media (max-width: 991px) {
      span {
        color: #00a4b6 !important;
      }
      .ts-icon {
        transform: rotate(-90deg);

        svg {
          fill: #00a4b6 !important;
          stroke: #00a4b6 !important;

          path {
            stroke: #00a4b6 !important;
          }
        }
      }
    }
  }
  .ts-line-2-trauncate {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}

.rtl {
  @media (max-width: 991px) {
    .ts-icon {
      transform: rotate(90deg) !important;
    }
  }
}
</style>
