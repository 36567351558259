<template>
  <div class="ts-addon-card ts-shadow-3 h-100">
    <div
      class="ts-addon-card__header d-flex flex-column flex-sm-row align-items-center mb-03 mb-md-4"
    >
      <div>
        <img
          class="ts-addon-card__img"
          width="36"
          :src="$store.state.baseUrl + image.data.attributes.url"
          :alt="$store.state.baseUrl + image.data.attributes.alternativeText"
        />
      </div>
      <div>
        <h5 class="ts-fs-5 ts-text-gray-5 fw-semibold mb-0">
          {{ title }} <br class="d-none d-sm-block" />
          {{ price }}
          <span class="ts-addon-card__month fw-regular">
            {{ month }}
          </span>
        </h5>
      </div>
    </div>
    <VueMarkdown
      class="ts-fs-6 ts-text-gray-1a ts-markdown-subscription-card-details-pass-down"
      :source="description"
    />
  </div>
</template>

<script>
import VueMarkdown from "markdown-vue";
export default {
  components: {
    VueMarkdown,
  },
  props: {
    title: String,
    price: String,
    description: String,
    image: String,
    month: String,
  },
};
</script>

<style lang="scss" scoped>
.ts-addon-card {
  padding: clamp(1rem, 4vw, 2rem);
  border-radius: 25px;
  &__header {
    gap: 22px;
    @media (max-width: 575px) {
      gap: 5px;
    }
  }
  &__img {
    width: clamp(70px, 7vw, 89px);
  }
  &__month {
    font-size: clamp(10px, 2.5vw, 17px);
  }
  .ts-fs-6 {
    font-size: clamp(18px, 4vw, 21px);
  }
}
</style>

<style lang="scss">
.ts-markdown-subscription-card-details-pass-down {
  ol li::marker {
    color: #00a4b6;
  }
}
</style>
