<template>
  <section
    class="ts-container-fluid container-fluid"
    v-if="$store.state.blogDetails"
  >
    <div class="ts-blog-article ts-text-gray-2">
      <div class="d-none d-md-block">
        <h1 class="ts-blog-article__title fw-bold mb-08 mb-lg-11">
          {{ $store.state.blogDetails.Title }}
        </h1>
        <p class="ts-blog-article__subtitle fw-regular mb-04 mb-lg-05">
          {{ $store.state.blogDetails.Information.author }} |
          {{ $store.state.blogDetails.createdAt.split("T")[0] }} |

          <span
            v-for="(topic, index) in $store.state.blogDetails.blog_topics.data"
            :key="topic.id"
          >
            {{ topic.attributes.Topic }}
            <span
              v-if="
                index !== $store.state.blogDetails.blog_topics.data.length - 1
              "
            >
              ,
            </span>
          </span>
        </p>
      </div>

      <img
        class="ts-main-img w-100 mb-06 mb-lg-14"
        :src="
          $store.state.baseUrl +
          $store.state.blogDetails.Image.data.attributes.url
        "
        :alt="$store.state.blogDetails.Image.data.attributes.alternativeText"
      />

      <div class="d-md-none mb-08">
        <h1 class="ts-blog-article__title fw-bold mb-02">
          {{ $store.state.blogDetails.Title }}
        </h1>
        <p class="ts-blog-article__subtitle fw-regular mb-0">
          {{ $store.state.blogDetails.Information.author }} |
          {{ $store.state.blogDetails.createdAt.split("T")[0] }} |
          <span
            v-if="
              index !== $store.state.blogDetails.blog_topics.data.length - 1
            "
          >
            ,
          </span>
        </p>
      </div>
      <div
        v-for="blogDetail in $store.state.blogDetails.BlogParagraph"
        :key="blogDetail.id"
      >
        <h2 class="ts-fs-2-ii fw-bold mb-07 mb-lg-10" v-if="blogDetail.Title">
          {{ blogDetail.Title }}
        </h2>
        <VueMarkdown
          class="ts-blog-desc fw-regular mb-07 mb-lg-10"
          :source="blogDetail.description"
        />
      </div>
    </div>
  </section>
</template>

<script>
import VueMarkdown from "markdown-vue";
export default {
  props: {
    title: String,
    date: String,
    generatedBy: String,
  },

  components: {
    VueMarkdown,
  },
};
</script>

<style lang="scss">
.ts-blog-article {
  max-width: 1100px;
  margin-inline: auto;
  margin-bottom: clamp(110px, 12vw, 11.25rem);

  &__title {
    font-size: clamp(1.5625rem, 5.5vw, 3.4375rem);
  }

  .ts-main-img {
    border-radius: clamp(18px, 3.5vw, 28px);
    object-fit: cover;
    min-height: 194px;
  }

  .ts-blog-desc {
    font-size: clamp(0.9375rem, 2.6vw, 1.75rem);
  }

  &__subtitle {
    font-size: clamp(11px, 2.5vw, 18px);
  }
}

.ts-news-card {
  .btn {
    font-size: 1.1875rem;

    @media (max-width: 991px) {
      span {
        color: #00a4b6 !important;
      }

      .ts-icon {
        transform: rotate(-90deg);

        svg {
          fill: #00a4b6 !important;
          stroke: #00a4b6 !important;

          path {
            stroke: #00a4b6 !important;
          }
        }
      }
    }
  }
}
</style>
