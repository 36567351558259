<template>
  <div>
    <!-- Check if link starts with 'https://' to determine if it's an external link -->
    <a
      v-if="isExternalLink(toSrc)"
      class="ts-link"
      target="_blank"
      rel="noopener noreferrer"
      :href="toSrc"
    >
      {{ text }}
    </a>
    <!-- If it's not an external link, use Vue's router-link -->
    <router-link v-else class="ts-link" :to="'/'+toSrc"> {{ text }}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    toSrc: String,
    text: String,
  },
  methods: {
    isExternalLink(url) {
      return url.startsWith("https://") || url.startsWith("http://");
    },
  },
};
</script>
