<template>
  <div
    class="accordion accordion-flush"
    id="tsFaqAccordion"
    v-if="$store.state.creatorFaq"
  >
    <div
      class="accordion-item"
      v-for="faqItem in $store.state.creatorFaq"
      :key="faqItem.id"
    >
      <h2 class="accordion-header">
        <button
          class="accordion-button ts-fs-5 collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#creator-faq-' + faqItem.id"
          aria-expanded="false"
          :aria-controls="'creator-faq-' + faqItem.id"
        >
          {{ faqItem.attributes.title }}
        </button>
      </h2>
      <div
        :id="'creator-faq-' + faqItem.id"
        class="accordion-collapse collapse"
        data-bs-parent="#tsFaqAccordion"
      >
        <div class="accordion-body ts-fs-5">
          <VueMarkdown
            v-for="desc in faqItem.attributes.description"
            :key="desc.id"
            :source="desc.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "markdown-vue";
export default {
  name: "FaqContent",

  components: {
    VueMarkdown,
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getCreatorFaq");
      }
    },
  },
  created() {
    this.$store.dispatch("getCreatorFaq");
  },
};
</script>

<style scoped lang="scss">
.accordion {
  --bs-accordion-btn-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28.1" height="16.527" viewBox="0 0 28.1 16.527"><path id="Path_937" data-name="Path 937" d="M0,0,10.368,10.492,21.029,0" transform="translate(3.536 3.535)" fill="none" stroke="%2300a4b6" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/></svg>');
  --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28.1" height="16.527" viewBox="0 0 28.1 16.527"><path id="Path_937" data-name="Path 937" d="M0,0,10.368,10.492,21.029,0" transform="translate(3.536 3.535)" fill="none" stroke="%2300a4b6" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/></svg>');

  .collapsed,
  &-item {
    background-color: transparent !important;
  }

  &-button {
    padding-inline: 0;
    box-shadow: none;
    color: #404040 !important;

    &:not(.collapsed) {
      color: var(--bs-accordion-active-color);
      background-color: transparent !important;
      box-shadow: 0 !important;
      outline: none !important;
    }
  }

  &-body {
    padding-inline: 0;
    color: #606060 !important;
  }

  &-button {
    font-weight: 600;
  }
}
</style>
