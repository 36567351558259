<template>
  <LoadingScreen v-if="!$store.state.brandPage" />
  <div>
    <TsHero />
    <div class="ts-container-fluid container-fluid">
      <BrandsLogos />
      <HarnessPower />
      <HowItWorks />
    </div>
    <BrandLoves v-bind:haveGradient="false" v-if="$store.state.brandPage">
      <h1 class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13">
        {{ $store.state.brandPage.Testimonial.title }}
        <span class="ts-text-primary-1">{{
          $store.state.brandPage.Testimonial.titleHighlighted
        }}</span>
        <br class="d-sm-none" />
        {{ $store.state.brandPage.Testimonial.title2 }}
      </h1>
    </BrandLoves>
    <div class="ts-container-fluid container-fluid">
      <LastestNews>
        <h1
          class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13"
          v-if="$store.state.brandPage"
        >
          {{ $store.state.brandPage.BlogSectionTitle }}
        </h1>
      </LastestNews>
    </div>
  </div>
</template>

<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import TsHero from "../components/Brands/TsHero.vue";
import BrandsLogos from "../components/Common/BrandsLogos.vue";
import HarnessPower from "../components/Brands/HarnessPower.vue";
import HowItWorks from "../components/Brands/HowItWorks.vue";
import BrandLoves from "../components/Common/BrandLoves.vue";
import LastestNews from "../components/Common/LastestNews.vue";

export default {
  name: "BrandsPage",
  components: {
    LoadingScreen,
    TsHero,
    BrandsLogos,
    HarnessPower,
    HowItWorks,
    BrandLoves,
    LastestNews,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getBrandPage");
      }
    },
  },
  created() {
    this.$store.dispatch("getBrandPage");
  },
};
</script>
