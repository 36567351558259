<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <nav
    class="navbar navbar-expand-lg fixed-top"
    :class="[
      { 'navbar--open': this.navbarOpen },
      { 'navbar--open--scroll': isScrolled },
      NavBarCustomClasses,
    ]"
    v-if="$store.state.header"
  >
    <div class="ts-container-fluid container-fluid">
      <!-- For LTR -->
      <router-link
        class="navbar-brand d-flex fw-bold ts-text-gray-5"
        to="/"
        @click="closeNavbar"
        :class="$store.state.isRtl ? 'd-none' : ''"
      >
        <div class="d-none d-sm-block">
          <img
            width="107"
            :src="
              $store.state.baseUrl +
              $store.state.header.logoLarge.data.attributes.url
            "
            :alt="$store.state.header.logoLarge.data.attributes.alternativeText"
          />
        </div>
        <div class="d-sm-none">
          <img
            width="36"
            :src="
              $store.state.baseUrl +
              $store.state.header.logoSmall.data.attributes.url
            "
            :alt="$store.state.header.logoLarge.data.attributes.alternativeText"
          />
        </div>
      </router-link>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleNavbar"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>

      <!-- For LTR -->
      <router-link
        class="navbar-brand d-flex fw-bold ts-text-gray-5"
        to="/"
        @click="closeNavbar"
        :class="$store.state.isRtl ? 'd-lg-none' : 'd-none'"
      >
        <div class="d-none d-sm-block">
          <img
            width="107"
            :src="
              $store.state.baseUrl +
              $store.state.header.logoLarge.data.attributes.url
            "
            :alt="$store.state.header.logoLarge.data.attributes.alternativeText"
          />
        </div>
        <div class="d-sm-none">
          <img
            width="36"
            :src="
              $store.state.baseUrl +
              $store.state.header.logoSmall.data.attributes.url
            "
            :alt="$store.state.header.logoLarge.data.attributes.alternativeText"
          />
        </div>
      </router-link>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul
          class="navbar-nav mb-2 mb-lg-0"
          :class="$store.state.isRtl ? 'pe-0' : 'ms-auto'"
        >
          <li
            class="nav-item dropdown mb-3 mb-lg-0"
            v-for="dropdown in $store.state.header.DropDown"
            :key="dropdown.id"
          >
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ dropdown.title }}
            </a>
            <ul class="dropdown-menu" :class="{ show: isDropDwonOpen }">
              <!-- class="dropdown-item nav-link--active" -->
              <li v-for="item in dropdown.DropdownItem" :key="item.id">
                <CustomLinkNav
                  class="dropdown-item"
                  :toSrc="item.to"
                  :text="item.text"
                  @click="closeNavbar"
                >
                </CustomLinkNav>
              </li>
            </ul>
          </li>
          <li
            class="nav-item"
            v-for="pageLink in $store.state.header.PageLink"
            :key="pageLink.id"
          >
            <router-link
              class="nav-link"
              :to="'/' + pageLink.to"
              @click="closeNavbar"
            >
              {{ pageLink.text }}
            </router-link>
          </li>

          <li class="nav-item d-flex align-items-center">
            <div class="ts-language-dropdown dropdown">
              <a
                class="ts-language-btn dropdown-toggle dropdown-toggle--white ts-btn ts-btn-primary rounded-2 text-uppercase"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ $store.state.activeLanguage }}
              </a>
              <ul class="dropdown-menu">
                <li
                  v-for="language in $store.state.languages"
                  :key="language.code"
                  @click="
                    () => {
                      this.$store.dispatch('switchLanguge', language.code);
                      if (language.code === 'ar') {
                        this.$store.dispatch('setIsRtl', true);
                      } else {
                        this.$store.dispatch('setIsRtl', false);
                      }
                    }
                  "
                >
                  <!-- v-if="language.code !== 'ar'" -->
                  <!-- v-if="language.code !== 'ar'" -->
                  <a class="dropdown-item text-uppercase" href="#">
                    {{ language.code }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <!-- For RTL -->
      <router-link
        class="navbar-brand d-flex fw-bold ts-text-gray-5"
        to="/"
        @click="closeNavbar"
        :class="$store.state.isRtl ? 'd-none d-lg-block' : 'd-none'"
      >
        <div class="d-none d-sm-block">
          <img
            width="107"
            :src="
              $store.state.baseUrl +
              $store.state.header.logoLarge.data.attributes.url
            "
            :alt="$store.state.header.logoLarge.data.attributes.alternativeText"
          />
        </div>
        <div class="d-sm-none">
          <img
            width="36"
            :src="
              $store.state.baseUrl +
              $store.state.header.logoSmall.data.attributes.url
            "
            :alt="$store.state.header.logoLarge.data.attributes.alternativeText"
          />
        </div>
      </router-link>
    </div>
  </nav>
</template>

<script>
import CustomLinkNav from "../Helpers/CustomLinkNav.vue";
export default {
  name: "TsNavbar",
  components: {
    CustomLinkNav,
  },
  props: {
    NavBarCustomClasses: String,
  },

  // Api Calling Start
  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getHeader");
      }
    },
  },
  created() {
    this.$store.dispatch("getHeader");
  },

  // Api Calling End

  data: function () {
    return {
      navbarOpen: false,
      isScrolled: false,
      isDropDwonOpen: false,
      Languages: null,
    };
  },
  methods: {
    isActive(route) {
      console.log(this.$route.path === route);
      return this.$route.path === route;
    },

    toggleNavbar() {
      // Call your custom method here before toggling the Navbar
      this.handleNavbarToggle();
      // Toggle the Navbar
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    handleNavbarToggle() {
      const navbar = document.querySelector(".navbar");
      const navbarToggler = document.querySelector(".navbar-toggler");
      if (navbarToggler && navbarToggler.classList.contains("collapsed")) {
        this.navbarOpen = false;
        document.body.classList.remove("body-overflow-hidden");
        navbar.classList.remove("navbar--open");
      } else {
        this.navbarOpen = true;
        navbar.classList.add("navbar--open");
        document.body.classList.add("body-overflow-hidden");
      }
    },
    handleScroll() {
      if (window.scrollY > 50) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },

    // Run A Function if screen resize or less then
    handleScreenResize() {
      const screenWidth = window.innerWidth;
      // Define the maximum screen width below which you want to run the function
      const maxScreenWidth = 991; // For example, 991px
      if (screenWidth < maxScreenWidth) {
        this.isDropDwonOpen = true;
      } else {
        this.isDropDwonOpen = false;
      }
    },

    // Navbar closed after clicking on navlink
    closeNavbar() {
      const navbar = document.querySelector(".navbar");
      const navbarToggler = document.querySelector(".navbar-toggler");
      const navbarCollapse = document.querySelector(".navbar-collapse");

      this.isNavbarOpen = !this.isNavbarOpen;
      document.body.classList.remove("body-overflow-hidden");
      navbar.classList.remove("navbar--open");
      // navbarToggler.classList.remove("collapsed")
      navbarToggler.setAttribute("aria-expanded", "false");
      navbarCollapse.classList.remove("show");
      this.handleScreenResize();
    },
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // Call the function on component mount to handle initial screen size
    window.addEventListener("resize", this.handleScreenResize);
    // Call the function on component mount to handle initial screen size
    this.handleScreenResize();

    // Getting Languages from Store
    this.$store.dispatch("getLnaguages");
  },
  // beforeUnmount() {
  //   window.removeEventListener("scroll", this.handleScroll);
  //   window.removeEventListener("resize", this.handleScreenResize);
  // },
};
</script>

<style lang="scss">
.navbar {
  background-color: rgba(255, 255, 255, 0) !important;

  &--open {
    background-color: white !important;
  }

  &--open--scroll {
    background-color: white !important;
  }
  .ts-language-btn {
    background-color: #757575 !important;
    border-color: #757575 !important;
    line-height: 100%;
    padding: 4px 0.5625rem 3px;
  }
}
.ts-language-dropdown {
  width: 67px !important;
  min-width: 67px !important;
  ul,
  li {
    padding: 0;
  }
  .dropdown-menu {
    min-width: 67px !important;
    width: 67px !important;
  }
  .dropdown-item {
    font-size: 22px;
    padding-block: 4.75px !important;
    line-height: 100%;
  }
}

.rtl {
  @media (min-width: 992px) {
    .navbar-nav {
      flex-direction: row-reverse !important;
    }
  }
}
</style>
