<template>
  <div
    class="ts-overview-card ts-overview-card--lg"
    :style="{
      backgroundImage:
        'url(' +
        $store.state.baseUrl +
        $store.state.overviewPage.PrivateFeedback.backgroundImage.data
          .attributes.url +
        ')',
    }"
  >
    <div class="ts-overview-card__content text-center mt-3 mb-auto">
      <h1 class="ts-fs-6-ii mb-0 fw-semibold text-center">
        {{ $store.state.overviewPage.PrivateFeedback.title }}
      </h1>
      <p class="ts-fs-7-ii mb-0 text-center">
        {{ $store.state.overviewPage.PrivateFeedback.description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivateFeedback",
};
</script>
