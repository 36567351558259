<template>
  <div class="ts-brand-loves position-relative isolate">
    <div class="ts-container-fluid container-fluid">
      <slot></slot>
    </div>
    <div
      v-if="haveGradient"
      class="ts-bg-radius ts-bg-radius--primary-2 z-minus-1"
    ></div>
    <div
      class="ts-container-fluid container-fluid overflow-hidden"
      v-if="$store.state.brandLoveUs"
    >
      <div>
        <swiper
          :slidesPerView="1"
          :spaceBetween="30"
          :loop="true"
          :pagination="{
            clickable: true,
          }"
          :autoplay="{
            delay: 3000,
            disableOnInteraction: false,
          }"
          :modules="modules"
          :breakpoints="{
            '700': {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            '1024': {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }"
          class="ts-custom-swiper brandLovesSwiper overflow-visible py-2"
        >
          <swiper-slide v-for="card in $store.state.brandLoveUs" :key="card.id">
            <div
              class="ts-creator-card ts-shadow-2 d-flex flex-column gap-4 justify-content-between"
            >
              <div>
                <img
                  class="ts-creator-card__img mb-05 mb-lg-08"
                  :src="
                    $store.state.baseUrl +
                    '/uploads/quote_close_gray_4b3c7ff32f.svg'
                  "
                  alt="alternativeText"
                />
                <h5
                  class="ts-brand-testimonial ts-fs-7 ts-text-gray-7 fw-light mb-03"
                >
                  {{ card.attributes.Testimonial }}
                </h5>
              </div>
              <div class="d-flex ts-rtl-row-reverse align-items-center gap-3">
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    :src="
                      $store.state.baseUrl +
                      card.attributes.Contact.Image.data.attributes.url
                    "
                    alt="alternativeText"
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-7 fw-medium mb-0">
                    {{ card.attributes.Contact.Name }}
                  </h5>
                  <p class="ts-fs-9 ts-text-gray-7 mb-0">
                    {{ card.attributes.Contact.Designation }}
                  </p>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },

  name: "BrandLoves",
  props: {
    haveGradient: Boolean,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getBrandLoveUs");
      }
    },
  },
  created() {
    this.$store.dispatch("getBrandLoveUs");
  },
};
</script>

<style scoped lang="scss">
.ts-brand-testimonial {
  height: 130px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
.ts-brand-loves {
  position: relative;
  isolation: isolate;
}
.ts-creator-card__img {
  height: clamp(30px, 5vw, 50px);
}
.ts-bg-radius {
  z-index: -1;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(10%, -45%);
  width: 1071px !important;
  height: 1071px !important;
  padding-top: 1071px !important;
  opacity: 50%;
  @media (max-width: 767px) {
    width: 434px !important;
    height: 434px !important;
    padding-top: 434px !important;
    transform: translate(30%, -26%);
    opacity: 100%;
  }
}
</style>
