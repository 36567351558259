<template>
  <div class="ts-adons-wrapper">
    <h1 class="ts-fs-1 ts-text-gray-5 fw-bold text-center mb-3 mb-md-1">
      {{ $store.state.subscriptionPage.AddOns.title }}
    </h1>
    <h2 class="ts-fs-3-iv ts-text-gray-5 fw-light text-center mb-08 mb-lg-10">
      {{ $store.state.subscriptionPage.AddOns.description }}
    </h2>

    <div class="ts-container-fluid container-fluid">
      <div class="row">
        <div class="col-xl-8 mb-03 mb-md-4 mb-xl-0">
          <div class="ts-addon-card-group">
            <div
              v-for="card in $store.state.subscriptionPage.AddOns.addonCard"
              :key="card.id"
            >
              <CardDetails
                :title="card.title"
                :price="card.price"
                :description="card.description"
                :image="card.image"
                :month="card.month"
              />
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="mb-4 h-100">
            <CardDetailsPass
              :title="$store.state.subscriptionPage.AddOns.priorityPasses.title"
              :price="$store.state.subscriptionPage.AddOns.priorityPasses.price"
              :description="
                $store.state.subscriptionPage.AddOns.priorityPasses.description
              "
              :image="$store.state.subscriptionPage.AddOns.priorityPasses.image"
              :month="$store.state.subscriptionPage.AddOns.priorityPasses.month"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardDetails from "@/components/Subscriptions/AddOn/CardDetails.vue";
import CardDetailsPass from "@/components/Subscriptions/AddOn/CardDetailsPass.vue";
export default {
  components: {
    CardDetails,
    CardDetailsPass,
  },
};
</script>

<style lang="scss" scoped>
.ts-addon-card-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
  }
}
.ts-adons-wrapper {
  margin-bottom: clamp(83px, 8vw, 113px);
}
</style>
