export default {
  baseUrl: process.env.VUE_APP_STRAPI_URL_ASSESTS,
  activeLanguage: "en",
  isRtl: false,
  languages: [],
  header: null,
  footer: null,
  brandsFaq: null,
  creatorFaq: null,
  departments: null,
  activeDepartment: null,
  brandsSwiper: null,
  brandLoveUs: null,
  creatorLoveUs: null,
  locations: null,
  countries: null,
  nationalities: null,
  activeJob: null,
  subscriptionPlans: null,
  subscriptionDurations: null,
  // Blog
  blogPagination: { page: 1, pageSize: 2 },
  activeBlogId: null,
  blogTitles: null,
  blogs: null,
  blogDetails: null,

  // Career
  careerPage: null,
  careers: null,

  // Pages
  notFound: null,
  blogPage: null,
  blogDetailsPage: null,
  homePage: null,
  overviewPage: null,
  subscriptionPage: null,
  creatorSignupPage: null,
  brandSignupPage: null,
  creatorPage: null,
  contactUsPage: null,
  brandPage: null,
  teamPage: null,
  brandFaqPage: null,
  creatorFaqPage: null,

  // Common
  TcPrivacy: null,
  errorMessges: null,
  utm: { utm_source: null, utm_medium: null, utm_campaign: null },
};
