<template>
  <section class="ts-form-hero ts-brand-form-hero">
    <div class="ts-bg-gradient d-lg-none">
      <div class="ts-gradient-bg-1 ts-bg-radius ts-bg-radius--secondary"></div>
      <div
        class="ts-gradient-bg-2 ts-bg-radius ts-bg-radius--primary-secondary--mix"
      ></div>
    </div>
    <div class="ts-container-fluid container-fluid">
      <div class="ts-brand-form-hero__content">
        <div v-show="elementVisible">
          <div class="ts-image ts-image__1 d-none d-md-block">
            <img src="/images/tiktok.png" alt="alternativeText" />
          </div>
          <div class="ts-image ts-image__2">
            <div class="ts-rotate">
              <img src="/images/Home/heart.png" alt="alternativeText" />
            </div>
          </div>
          <div class="ts-image ts-image__3 d-none d-md-block">
            <img src="/images/Home/camera.png" alt="alternativeText" />
          </div>
          <div class="ts-image ts-image__4 d-none d-md-block">
            <img src="/images/mic.png" alt="alternativeText" />
          </div>
          <div class="ts-image ts-image__5 d-none d-md-block">
            <div class="ts-rotate">
              <img src="/images/Home/heart.png" alt="alternativeText" />
            </div>
          </div>
        </div>
        <div
          class="ts-brand-form-hero__content--main row row-cols-lg-2 align-items-center gap-5 gap-lg-0"
        >
          <div class="order-1 order-lg-0 ts-text">
            <header
              class="ts-brand-form-hero__header ts-text-gray-2b"
              v-if="$store.state.brandSignupPage"
            >
              <img
                height="43"
                class="mb-03"
                :src="
                  $store.state.baseUrl +
                  '/uploads/quote_close_svgrepo_com_4c34c2e02c.svg'
                "
                alt="alternativeText"
              />
              <h1 class="ts-text-gray-2b ts-fs-2-b fw-bold mb-04 mb-lg-07">
                {{ $store.state.brandSignupPage.mainDetails.title }}
              </h1>
              <p class="ts-text-gray-2b ts-fs-5 fw-light mb-05 mb-lg-07">
                {{ $store.state.brandSignupPage.mainDetails.description }}
              </p>
              <div
                class="d-flex align-items-center gap-3 ts-rtl-row-reverse ts-rtl-justify-content-end"
              >
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    src="/images/happy-girl-posing.png"
                    alt="alternativeText"
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-1a fw-medium mb-0">
                    {{
                      $store.state.brandSignupPage.mainDetails
                        .TestimonialContact.Name
                    }}
                  </h5>
                  <p class="ts-fs-9 ts-text-gray-1a mb-0">
                    {{
                      $store.state.brandSignupPage.mainDetails
                        .TestimonialContact.Designation
                    }}
                  </p>
                </div>
              </div>
            </header>
          </div>
          <div
            class="order-0 order-lg-1 ts-form"
            v-if="$store.state.brandSignupPage"
          >
            <div class="ts-contact">
              <div class="ts-contact__content">
                <div class="ts-contact-form-container ts-shadow-1">
                  <div class="mb-3 position-relative">
                    <button
                      class="ts-btn ts-btn--sm border-0 ts-btn--back ts-fs-7-ii position-absolute left-0 top-0 p-1 px-2 rounded-1 mt-1 text-center"
                      @click="handleSlideBack"
                      :class="[isLastSlide ? 'd-none' : '']"
                    >
                      <Icon name="chevron-left" />
                    </button>
                    <swiper
                      :slides-per-view="1"
                      @swiper="onSwiper"
                      :spaceBetween="16"
                      :pagination="{
                        clickable: false,
                        el: '.ts-brands-form-swiper',
                      }"
                      :modules="modules"
                      class="ts-brands-swiper mySwiper"
                      :class="isLastSlide ? 'd-none' : ''"
                      ref="tsbrandsSwiper"
                      :allowTouchMove="false"
                      :loop="false"
                      @slideChange="handleSlideChange"
                    >
                      <swiper-slide>
                        <Form1
                          @submit-form="FromSumbitStatus"
                          ref="brandSignupform1"
                        />
                      </swiper-slide>
                      <swiper-slide>
                        <Form2
                          @submit-form="FromSumbitStatus"
                          @request-status="requestStatus"
                          ref="brandSignupform2"
                          :brand-id="brand_id"
                        />
                      </swiper-slide>
                      <swiper-slide class="h-auto">
                        <Form3
                          @submit-form="FromSumbitStatus"
                          ref="brandSignupform3"
                          :brand-id="brand_id"
                        />
                      </swiper-slide>
                    </swiper>

                    <div
                      class="ts-congratulation"
                      :class="isLastSlide ? '' : 'd-none'"
                    >
                      <Congratulation v-if="$store.state.brandSignupPage">
                        <h1
                          class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01"
                        >
                          {{
                            $store.state.brandSignupPage.Congratulation.title
                          }}
                        </h1>
                        <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-3">
                          {{
                            $store.state.brandSignupPage.Congratulation
                              .description
                          }}
                        </p>
                      </Congratulation>
                    </div>
                    <div
                      class="ts-brands-form-swiper ts-swiper-pagination-2 d-flex justify-content-center"
                      :class="isLastSlide ? 'invisible' : ''"
                    ></div>
                  </div>
                  <div v-if="$store.state.brandSignupPage">
                    <button
                      v-if="!processingStatus"
                      class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-100 rounded-pill mt-auto text-center"
                      @click="handleFormSubmit"
                      :class="[
                        btnText === 'Continue'
                          ? 'ts-btn-primary'
                          : 'ts-btn-secondary',
                        isLastSlide ? 'd-none' : '',
                      ]"
                    >
                      {{ btnText }}
                    </button>
                    <button
                      v-else
                      class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-100 rounded-pill mt-auto text-center"
                      :class="[
                        btnText ===
                        $store.state.brandSignupPage.FormActions.ContinueBtnText
                          ? 'ts-btn-primary'
                          : 'ts-btn-secondary',
                        isLastSlide ? 'd-none' : '',
                      ]"
                    >
                      <i
                        class="spinner-border spinner-border-sm text-light"
                        role="status"
                      ></i>
                      <!-- {{ btnText }} -->
                    </button>
                    <!-- <router-link
                      v-if="isLastSlide"
                      class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-secondary w-100 rounded-pill get-demo-button"
                      :to="'/' + $store.state.brandSignupPage.GetADemo.to"
                    >
                      {{
                        $store.state.brandSignupPage.GetADemo.text
                      }}</router-link
                    > -->
                    <button
                      class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-100 rounded-pill text-center"
                      @click="handleCongratulations"
                      :class="isLastSlide ? '' : 'd-none'"
                    >
                      {{ $store.state.brandSignupPage.FormActions.DoneBtnText }}
                    </button>
                  </div>

                  <h3
                    class="ts-fs-7-ii text-center ts-already-signup mb-0 fw-normal"
                    :class="isLastSlide ? 'invisible' : ''"
                    v-if="$store.state.brandSignupPage"
                  >
                    {{
                      $store.state.brandSignupPage.PageLinks
                        .alreadyHaveAnAccount.text
                    }}

                    <router-link
                      class="fw-bold ts-text-primary-1 text-decoration-none fw-bold"
                      :to="
                        $store.state.brandSignupPage.PageLinks
                          .alreadyHaveAnAccount.linkTo
                      "
                    >
                      {{
                        $store.state.brandSignupPage.PageLinks
                          .alreadyHaveAnAccount.linkText
                      }}
                    </router-link>
                  </h3>
                </div>

                <h3
                  class="ts-fs-7-ii text-center ts-already-signup-2 fw-normal"
                  v-if="$store.state.brandSignupPage"
                >
                  {{
                    $store.state.brandSignupPage.PageLinks.areYouACreator.text
                  }}

                  <router-link
                    class="fw-bold ts-text-primary-1 text-decoration-none fw-bold"
                    :to="
                      $store.state.brandSignupPage.PageLinks.areYouACreator
                        .linkTo
                    "
                  >
                    {{
                      $store.state.brandSignupPage.PageLinks.areYouACreator
                        .linkText
                    }}
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <!-- v-show="elementVisible" -->
        <img
          v-show="elementVisibleBG"
          class="ts-brands-bg-2"
          src="/images/logo_bg.png"
          alt="alternativeText"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "vue";
import Form1 from "./brands/Form1.vue";
import Form2 from "./brands/Form2.vue";
import Form3 from "./brands/Form3.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import router from "@/router";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Navigation } from "swiper/modules";

import Congratulation from "./common/Congratulation.vue";
import Icon from "../../assets/icons/index.vue";
const formStatus = ref(false);
const processingStatus = ref(false);
export default {
  name: "BrandSignupHero",
  components: {
    Swiper,
    SwiperSlide,
    Form1,
    Form2,
    Form3,
    Congratulation,
    Icon,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getErrorMessages");
      }
    },
  },

  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },

  data() {
    return {
      processingStatus: processingStatus,
      brand_id: "",
      btnText: "Continue",
      isLastSlide: false,
      isLastSlideActive: false,
      swiper: null,
      elementVisible: false,
      elementVisibleBG: false,
    };
  },
  created() {
    setTimeout(() => (this.elementVisible = true), 100);
    setTimeout(() => (this.elementVisibleBG = true), 900);
  },

  beforeCreate() {
    this.$store.dispatch("getCountries");
    this.$store.dispatch("getNationalities");
    this.$store.dispatch("getErrorMessages");
  },
  methods: {
    onSubmit() {
      console.log("Working");
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    handleSlideChange() {
      this.isLastSlideActive = false;
      this.btnText = "Continue";
      if (this.swiper.isEnd) {
        this.btnText = "Done";
      }
    },
    handleFormSubmit() {
      switch (this.swiper.realIndex) {
        case 0:
          processingStatus.value = true;
          formStatus.value = false;
          this.$refs.brandSignupform1.submitFrom1();
          break;
        case 1:
          processingStatus.value = true;
          formStatus.value = false;
          this.$refs.brandSignupform2.submitFrom2();
          break;
        case 2:
          processingStatus.value = true;
          formStatus.value = false;
          this.$refs.brandSignupform3.verifyPin();
          break;
        default:
          break;
      }
    },
    handleSlideNext() {
      if (formStatus.value) {
        // this.isLastSlide = false;
        this.swiper.slideNext();
        this.btnText = "Continue";

        if (this.isLastSlideActive) {
          this.isLastSlide = true;
        }

        if (this.swiper.isEnd) {
          this.btnText = "Done";
          this.isLastSlideActive = true;
        }
      }
    },
    handleSlideBack(e) {
      if (!processingStatus.value) {
        this.isLastSlide = false;
        this.swiper.slidePrev();

        // if (this.isLastSlideActive) {
        //   this.isLastSlide = true;
        // }
        if (this.swiper.isBeginning) {
          e.target.disabled;
        }
      }
    },
    handleCongratulations() {
      router.push({ path: "/" });
      // this.swiper.slideTo(0);
      // this.isLastSlide = false;
    },
    FromSumbitStatus(i) {
      formStatus.value = i.status;
      processingStatus.value = false;
      if (i.status) {
        this.brand_id = i.brand_id;
      }
      this.handleSlideNext();
    },
    requestStatus(i) {
      processingStatus.value = i.status;
    },
  },
};
</script>
<style lang="scss" scoped>
.ts-btn--back {
  z-index: 20;
  background-color: transparent;
}

.ts-congratulation {
  min-height: 365px;
  max-width: 393px;
  margin: auto;

  @media (min-width: 992px) {
    min-height: 413px;
  }
}
.get-demo-button {
  text-align: center;
  margin-bottom: 13px;
}
// .ts-brands-bg-2 {
//   position: absolute;
//   left: 100%;
//   top: 25%;
//   width: 2160px;
//   transform: translate(-35%, -50%) !important;
//   z-index: -2;

//   @media (min-width: 992px) {
//     left: 75%;
//     top: 48%;
//     transform: translate(-50%, -50%) !important;
//   }
// }

.ts-bg-gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.ts-gradient-bg-1 {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -10%);
  width: clamp(1200px, 140vw, 1700px);
  z-index: -3;
}

.ts-gradient-bg-2 {
  position: absolute;
  top: 22%;
  right: 0;
  transform: translate(94%, 0%);
  width: clamp(1200px, 140vw, 1700px);
  z-index: -3;
}

.ts-brand-form-hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;

  width: 100%;
  overflow: hidden;

  &__content {
    position: relative;
    isolation: isolate;
    max-width: 1224px;
    margin: auto;

    &--main {
      position: relative;
      isolation: isolate;
      z-index: 10;
    }
  }

  .ts-text {
    position: relative;
    z-index: 1;
  }

  .ts-form {
    position: relative;
    z-index: 2;
  }
}

.ts-form-hero {
  padding-block: 6.5625rem 3.5rem;
  position: relative;
  isolation: isolate;

  @media (min-width: 992px) {
    padding-block: 12rem 6rem;
  }
}

@media (max-width: 475px) {
  .ts-brand-form-hero__header {
    max-width: 338px;
    margin: auto;
  }
}

.ts-contact {
  margin: 0 !important;
  position: relative;
  isolation: isolate;
}

.ts-image {
  position: absolute;

  img {
    width: clamp(80px, 11vw, 120px) !important;
  }

  &__1 {
    img {
      animation: floating 2.5s ease-in-out infinite;
    }
  }

  &__2 {
    img {
      animation: floating 3s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(30deg);
    }
  }

  &__3 {
    img {
      animation: floating 3.5s ease-in-out infinite;
    }
  }

  &__4 {
    img {
      animation: floating 4s ease-in-out infinite;
    }
  }

  &__5 {
    img {
      animation: floating 4s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(-30deg);
    }
  }

  &__1,
  &__3,
  &__5 {
    left: 40%;
    transform: translateX(-100%);
  }

  &__2,
  &__4 {
    right: 0;
    transform: translateX(100%);
  }

  &__1,
  &__2 {
    transform: translateY(-100%);
  }

  &__1 {
    left: 41%;
    top: 32%;
  }

  &__2 {
    top: 109%;
    right: -5%;
  }

  &__3 {
    top: 15%;
    left: 0;
    transform: translate(-100%, -100%);
  }

  &__4 {
    right: 65% !important;
    top: 100%;
    transform: translate(100%, -100%);

    img {
      width: clamp(80px, 15vw, 170px) !important;
    }
  }

  &__5 {
    bottom: 0;
    left: 5%;
    transform: translate(-120%, 40%);
  }

  @media (max-width: 1400px) {
    &__3 {
      transform: translate(0, -100%) !important;
    }

    &__5 {
      left: 0;
      transform: translate(0, 40%) !important;
    }
  }

  @media (max-width: 991px) {
    &__1,
    &__3,
    &__4,
    &__5 {
      display: none !important;
    }

    &__2 {
      top: 75%;
      right: 0;
    }
  }
}

@keyframes floating {
  0% {
    transform: rotate(-5deg) translatey(0px);
  }

  50% {
    transform: rotate(-3deg) translatey(7px);
  }

  100% {
    transform: rotate(-5deg) translatey(0px);
  }
}
</style>
