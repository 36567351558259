<template>
  <section class="ts-plans-wrapper mb-14">
    <div class="ts-container-fluid container-fluid">
      <h1 class="ts-fs-1 ts-text-gray-5 fw-bold text-center mb-3 mb-md-1">
        {{ $store.state.subscriptionPage.PlansOverview.title }}
      </h1>
      <h2
        class="ts-fs-3-iii ts-text-gray-5 fw-light text-center mb-08 mb-lg-12"
      >
        {{ $store.state.subscriptionPage.PlansOverview.description }}
      </h2>

      <ul class="list-unstyled ts-plan-table">
        <li class="ts-plan-table__row ts-plan-table__head d-none d-md-grid">
          <h3 class="fw-semibold ts-text-gray-4-ii mb-0 ts-text-primary-1">
            {{ $store.state.subscriptionPage.PlansOverview.features }}
          </h3>
          <h3 class="fw-semibold ts-text-gray-4-ii mb-0 text-center">
            {{ $store.state.subscriptionPage.PlansOverview.essential }}
          </h3>
          <h3 class="fw-semibold ts-text-gray-4-ii mb-0 text-center">
            {{ $store.state.subscriptionPage.PlansOverview.premium }}
          </h3>
          <h3 class="fw-semibold ts-text-gray-4-ii mb-0 text-center">
            {{ $store.state.subscriptionPage.PlansOverview.pro }}
          </h3>
        </li>
        <li
          class="ts-plan-table__row"
          v-for="planItem in $store.state.subscriptionPage.PlansOverview
            .subscriptionPlanList"
          :key="planItem.id"
        >
          <p class="first-elem mb-0 ts-text-gray-5-i text-center text-md-start">
            {{ planItem.description }}
          </p>
          <div class="text-center">
            <h3
              class="fw-semibold ts-text-gray-4-ii mb-0 text-center d-md-none"
            >
              {{ $store.state.subscriptionPage.PlansOverview.essential }}
            </h3>

            <Icon v-if="planItem.essential" name="check-circle-filled-lg" />
            <Icon v-else name="x-circle-filled-lg" />
          </div>
          <div class="text-center">
            <h3
              class="fw-semibold ts-text-gray-4-ii mb-0 text-center d-md-none"
            >
              {{ $store.state.subscriptionPage.PlansOverview.premium }}
            </h3>
            <Icon v-if="planItem.premium" name="check-circle-filled-lg" />
            <Icon v-else name="x-circle-filled-lg" />
          </div>
          <div class="text-center">
            <h3
              class="fw-semibold ts-text-gray-4-ii mb-0 text-center d-md-none"
            >
              {{ $store.state.subscriptionPage.PlansOverview.pro }}
            </h3>
            <Icon v-if="planItem.pro" name="check-circle-filled-lg" />
            <Icon v-else name="x-circle-filled-lg" />
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Icon from "@/assets/icons/index.vue";
export default {
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.ts-plans-wrapper {
  margin-bottom: clamp(83px, 8vw, 113px);
}
.ts-plan-table {
  border-radius: 11px;
  overflow: hidden;

  -webkit-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.16);

  &__head {
    // background-color: #f6f6f6;
    * {
      font-size: clamp(20px, 4vw, 27px) !important;
    }
  }
  &__row {
    display: grid;
    grid-template-columns: 45% 1fr 1fr 1fr;
    padding-block: 1rem;
    padding-left: clamp(20px, 5vw, 70px);

    @media (max-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
      padding-inline: 12px;
      gap: 0.75rem;
      .first-elem {
        grid-column: span 4; /* Span full width */
      }
    }
    * {
      font-size: clamp(19px, 4vw, 26px);
    }

    &:nth-child(odd) {
      background-color: #f6f6f6;
    }

    svg {
      width: 28px !important;
    }
  }
}
</style>
