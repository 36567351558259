<template>
  <div class="h-100 d-flex flex-column">
    <header
      class="ts-contact-form-container__header ts-text-gray-4 text-center my-0"
    >
      <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
        {{ $store.state.creatorSignupPage.Form3.title }}
      </h1>
      <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-0">
        {{ $store.state.creatorSignupPage.Form3.description }}
      </p>
    </header>
    <form class="mt-auto mb-5" @submit.prevent="sendOTP">
      <div class="mb-07">
        <p class="ts-fs-7 px-3">
          {{ $store.state.creatorSignupPage.Form3.varifyYourEmailLabel }}
        </p>
        <div class="ts-custom-email-input">
          <input
            type="email"
            class="form-control form-control-lg rounded-pill"
            id="EmailAddressHelp"
            aria-describedby="EmailAddressHelp"
            :placeholder="
              $store.state.creatorSignupPage.Form3.varifyYourEmailPlaceholder
            "
            v-model="email"
            :class="{ 'is-invalid': emailError }"
          />
          <button
            v-if="!requestStatus"
            @click.prevent="sendOTP"
            class="ts-btn ts-btn-secondary rounded-pill"
          >
            {{ $store.state.creatorSignupPage.Form3.varifyYourEmailBtn }}
          </button>
          <button v-else class="ts-btn ts-btn-secondary rounded-pill">
            <i
              class="spinner-border spinner-border-sm text-light"
              role="status"
            ></i>
          </button>
          <!-- <div class="invalid-feedback">{{ emailErrorMessages[0] }}</div> -->
        </div>
      </div>
      <p class="ts-sentyou-otp ts-fs-7 fw-light mb-03 text-center mb-04">
        {{ $store.state.creatorSignupPage.Form3.otpDescription }}
      </p>

      <div class="ts-otp-input">
        <v-otp-input
          ref="otpInput"
          v-model:value="bindModal"
          input-classes="otp-input"
          separator=""
          :num-inputs="4"
          :should-auto-focus="true"
          input-type="letter-numeric"
          :conditionalClass="['one', 'two', 'three', 'four']"
          :placeholder="[' ', ' ', ' ', ' ']"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { axiosAPI } from "../../../axiosClient";
import { toast } from "vue3-toastify";
const otpStatus = ref(false);
const requestStatus = ref(false);
export default {
  name: "CreatorForm3",
  props: ["creatorId"],
  components: {},

  data() {
    return {
      requestStatus: requestStatus,
      email: "",
      pin: "",
      deleted_reapply_confirmation: false,
      emailError: false,
      emailErrorMessages: [],
    };
  },
  watch: {
    email() {
      this.emailError = false;
      this.emailErrorMessages = [];
    },
  },
  methods: {
    sendOTP() {
      if (!requestStatus.value) {
        this.$emit("requestStatus", {
          status: false,
        });
        requestStatus.value = true;
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (this.email == "") {
          this.emailError = true;
          this.emailErrorMessages.push("email_empty");
        } else {
          this.emailErrorMessages.pop("email_empty");
        }
        if (!regex.test(this.email)) {
          this.emailError = true;
          this.emailErrorMessages.push("invalid_email");
        } else {
          this.emailErrorMessages.pop("invalid_email");
        }
        if (!this.emailError) {
          let FormData = {
            creator_id: this.creatorId,
            email: this.email,
            deleted_reapply_confirmation: this.deleted_reapply_confirmation,
            step: 3,
          };
          axiosAPI
            .post(`v1/creator/web/signup`, FormData)
            .then((response) => {
              this.deleted_reapply_confirmation = false;
              this.$emit("requestStatus", {
                status: true,
              });
              requestStatus.value = false;
              if (response.data && response.data.status) {
                if (this.$store.state.errorMessges) {
                  toast.success(this.$store.state.errorMessges.otp_sent);
                }
              } else {
                if (response.data && response.data.deleted_account) {
                  if(confirm("We've noticed a prior association with your profile. To enhance your experience, we are linking your request with the old association. Would you like to proceed?")) {
                    this.deleted_reapply_confirmation = true;
                    this.sendOTP();
                  } else {
                    this.$emit("submitForm", {
                      status: false,
                      redirect: true
                    });
                  }
                } else {
                  if (response.data && response.data.original) {
                    this.emailError = true;
                    toast.error(response.data.original.message);
                    // this.emailErrorMessages.push(response.data.original.message);
                  }
                }
              }
            })
            .catch(() => {
              this.$emit("requestStatus", {
                status: true,
              });
              requestStatus.value = false;
            });
        } else {
          this.$emit("requestStatus", {
            status: true,
          });
          requestStatus.value = false;
        }
      }
    },
    verifyPin() {
      if (otpStatus.value) {
        let FormData = {
          creator_id: this.creatorId,
          email: this.email,
          otp: this.pin,
        };
        let _this = this;
        axiosAPI
          .post(`v1/creator/web/verify-otp`, FormData)
          .then((response) => {
            if (response.data && response.data.status) {
              this.$emit("submitForm", {
                status: true,
                creator_id: this.creatorId,
              });
            } else {
              this.$emit("submitForm", {
                status: false,
              });
              if (this.$store.state.errorMessges) {
                toast.error(this.$store.state.errorMessges.invalid_pin);
              }
            }
          })
          .catch(function (error) {
            _this.$emit("submitForm", {
              status: false,
            });
            if (error.response && error.response.data) {
              let error_msgs = [];
              for (const error_msg in error.response.data) {
                error_msgs.push(...error.response.data[error_msg]);
                // _this.FromErrorMessages[error_msg] = error.response.data[error_msg];
                _this.FromErrorFlags[error_msg] = true;
              }
              if (this.$store.state.errorMessges) {
                toast.info(this.$store.state.errorMessges[error_msgs[0]]);
              }
            }
          });
      } else {
        this.$emit("submitForm", {
          status: false,
        });
        if (this.$store.state.errorMessges) {
          toast.info(this.$store.state.errorMessges.enter_otp);
        }
      }
    },
    handleOnComplete(data) {
      this.pin = data;
      otpStatus.value = true;
    },
    handleOnChange(data) {
      if (data.length != 4) otpStatus.value = false;
    },
  },
};
</script>

<style lang="scss">
.ts-sentyou-otp {
  max-width: 317px;
  margin-inline: auto;
}

.ts-otp-input {
  display: flex;
  justify-content: center;
}

.otp-input {
  width: 58px !important;
  height: 50px !important;
  padding: 5px;
  margin: 0 6px;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  text-align: center;
  background-color: #ebebeb;

  &::placeholder {
    content: "";
  }

  &:focus {
    border: 1px solid #c0c0c0;
    outline: none;
  }
}

/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #ebebeb;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
