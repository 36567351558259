<template>
  <LoadingScreen v-if="!$store.state.blogPage" />
  <div>
    <TsHero />
    <div class="ts-container-fluid container-fluid">
      <div class="mb-10 mb-lg-12">
        <section class="blog-card-group-wrapper">
          <div
            class="d-none d-md-block ts-bg-radius ts-bg-radius--secondary z-minus-1"
          ></div>
          <div
            class="d-none d-md-block ts-bg-radius ts-bg-radius--primary-secondary--mix z-minus-1"
          ></div>

          <img
            class="d-none d-md-block ts-bg-1"
            src="/images/bg-decoration-lines.svg"
            alt="alternativeText"
          />
          <img
            class="d-none d-md-block ts-bg-2"
            src="/images/bg-decoration-lines.svg"
            alt="alternativeText"
          />
          <img
            class="d-none d-md-block ts-bg-3"
            src="/images/hash-white.svg"
            alt="alternativeText"
          />

          <div class="mb-08 d-md-none">
            <select
              class="form-select ts-form-select rounded-pill py-2"
              aria-label="Default select example"
              @change="updateActiveIdSelect"
            >
              <option
                v-for="tag in $store.state.blogTitles"
                :key="tag.id"
                :value="tag.id"
              >
                {{ tag.attributes.Topic }}
              </option>
            </select>
          </div>
          <div
            class="d-none d-md-flex flex-wrap gap-07p mb-14 ts-rtl-row-reverse"
          >
            <button
              class="ts-btn-tags"
              v-for="tag in $store.state.blogTitles"
              :key="tag"
              :data-id="tag.id"
              :class="{
                'ts-btn-tags--active': $store.state.activeBlogId == tag.id,
              }"
              @click="updateActiveId"
            >
              {{ tag.attributes.Topic }}
            </button>
          </div>

          <div v-if="!$store.state.blogs || !$store.state.blogs.length">
            <div
              class="ts-no-data-wrapper d-flex justify-content-center align-items-center"
            >
              <h1 class="text-center mb-14" v-if="$store.state.blogPage">
                {{ $store.state.blogPage.noDataAvailable }}
              </h1>
            </div>
          </div>
          <div v-else>
            <div class="blog-card-group mb-11 mb-lg-14">
              <div v-for="card in $store.state.blogs" :key="card.id">
                <NewsCard
                  :title="card.attributes.Title"
                  :imageUrl="card.attributes.Image.data.attributes.url"
                  :date="card.attributes.Information.date.split('T')[0]"
                  :generatedBy="card.attributes.Information.author"
                  :id="card.id"
                  :readMore="card.attributes.readMore"
                />
              </div>
            </div>

            <Pagination />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import TsHero from "../components/Blog/TsHero.vue";
import NewsCard from "../components/Cards/NewsCard.vue";
import Pagination from "../components/Blog/Pagination.vue";

export default {
  name: "BlogPage",
  components: {
    LoadingScreen,
    TsHero,
    NewsCard,
    Pagination,
  },
  computed: {
    visibleCards() {
      return this.cards.slice(0, this.itemsToShow);
    },
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },
  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.callBlogTopics();
        this.callBlogs(1);
        this.$store.dispatch("getBlogPage");
      }
    },
  },
  created() {
    this.callBlogTopics();
    this.callBlogs(1);
    this.$store.dispatch("getBlogPage");
  },

  methods: {
    // updata Blog on  tag click
    updateActiveId(event) {
      const clickedElement = event.target;
      // // Get the data attribute value
      const topicId = clickedElement.getAttribute("data-id");
      // // change the active button State
      this.callBlogs(topicId, 1);
    },
    // updata Blog onn Select Cahnge
    updateActiveIdSelect(event) {
      this.callBlogs(event.target.value, 1);
    },

    callBlogTopics() {
      this.$store.dispatch("getBlogTitles");
    },
    callBlogs(topicId, page) {
      this.$store.dispatch("updateActiveBlog", topicId);

      if (page === undefined) {
        page = this.$store.state.blogPagination.page;
      }
      this.$store.dispatch("getBlogs", {
        topicId,
        page: page,
      });
    },
  },
};
</script>

<style scoped lang="scss">
// .blog-card-group-wrapper-outer {
//   width: 100%;
//   overflow: hidden;
// }

.ts-no-data-wrapper {
  min-height: 50vh;
}
.blog-card-group {
  &-wrapper {
    position: relative;
    isolation: isolate;

    .ts-bg-1 {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(75%, -50%);
      z-index: -3;
      width: clamp(2000px, 180vw, 3000px);
    }

    .ts-bg-2 {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-60%, 50%);
      z-index: -2;
      width: clamp(2000px, 180vw, 3000px);
    }

    .ts-bg-3 {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(10%, 30%);
      z-index: -1;
      width: clamp(200px, 40vw, 450px);
    }
  }

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 36px;
  grid-row-gap: clamp(60px, 9vw, 104px);

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.ts-bg-radius--secondary {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 1320px !important;
  height: 1320px !important;
  z-index: -4;
  opacity: 83%;
}

.ts-bg-radius--primary-secondary--mix {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-70%, 84%) rotate(45deg);
  width: 819px !important;
  height: 819px !important;
  z-index: -3;
  opacity: 83%;
}

.ts-btn-tags {
  color: #404040;
  background-color: #f6f6f6;
  font-size: 23px;
  border-radius: 50rem;
  border: 1px solid #707070;
  padding: 10px 43px 13px;
  line-height: 100%;
  transition: all 300ms ease-in-out;

  &--active {
    background-color: #00a4b6;
    border-color: #00a4b6;
    color: white;
  }
}
</style>
