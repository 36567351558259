<template>
  <section class="ts-hero d-flex justify-content-center align-items-center">
    <div class="ts-container-fluid container-fluid">
      <div class="row row-cols-xl-2 align-items-center">
        <div class="order-1 order-xl-0 text-center text-xl-start">
          <h1
            class="ts-hero__title ts-fs-1 ts-text-gray-5 fw-bold mb-05 mb-xl-07 mx-auto mx-xl-0 ms-rtl-auto"
            v-if="$store.state.blogPage"
          >
            {{ $store.state.blogPage.Banner.title }}
          </h1>
          <p
            class="ts-hero__desc ts-fs-5 fw-light mx-auto mx-xl-0 ms-rtl-auto"
            v-if="$store.state.blogPage"
          >
            {{ $store.state.blogPage.Banner.description }}
          </p>
        </div>
        <div
          class="d-flex d-xl-none justify-content-center order-0 order-xl-1 mb-08 mb-xl-0"
        >
          <img class="ts-hero__img w-100" src="/images/blog/1.png" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TsHero",
};
</script>
<style scoped lang="scss">
.ts-hero {
  position: relative;
  z-index: 1;
  background-image: url("../../../public/images/blog/hero-bg-sm.jpg");
  margin-bottom: 100px;
  @media (min-width: 1201px) {
    background-image: url("../../../public/images/blog/hero-lg/blog_1440p.jpg");
    &__title {
      max-width: 590px;
    }

    &__desc {
      max-width: 520px;
    }
  }
  @media (min-width: 2561px) {
    background-image: url("../../../public/images/blog/hero-lg/blog_2160p.jpg");
  }
  @media (min-width: 2800px) {
    background-image: url("../../../public/images/blog/hero-lg/blog.jpg");
  }
  @media (max-width: 991px) {
    margin-bottom: 46px;
  }
  &__img {
    max-width: 550px;
  }
}
</style>
