<template>
  <div class="ts-footer" v-if="$store.state.footer">
    <div class="ts-container-fluid container-fluid">
      <div class="ts-footer__main">
        <div class="ts-footer__main-1">
          <img
            class=""
            width="92"
            :src="
              $store.state.baseUrl +
              $store.state.footer.logo.data.attributes.url
            "
            :alt="
              $store.state.footer.logo.data.attributes.alternativeText == ''
                ? $store.state.footer.logo.data.attributes.alternativeText
                : 'Alist Logo'
            "
          />
          <p class="ts-text-white-2 mt-3 mb-0 ts-copy-text">
            <span class="ts-copy ts-text-white-2"> &copy;</span>
            {{ $store.state.footer.copyRightText }}
          </p>
        </div>
        <div class="ts-footer__main-2 d-flex justify-content-center">
          <div class="ts-footer__main-content d-flex text-nowrap">
            <div>
              <ul class="list-unstyled mb-0 d-flex flex-column gap-3">
                <li class="ts-text-primary-2 ts-fs-5-ii fw-bold mb-2">
                  {{ $store.state.footer.Brands.title }}
                </li>

                <li
                  v-for="link in $store.state.footer.Brands.link"
                  :key="link.id"
                >
                  <CustomLink :text="link.text" :toSrc="link.to" />
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-unstyled mb-0 d-flex flex-column gap-3">
                <li class="ts-text-primary-2 ts-fs-5-ii fw-bold mb-2">
                  {{ $store.state.footer.Creators.title }}
                </li>
                <li
                  v-for="link in $store.state.footer.Creators.link"
                  :key="link.id"
                >
                  <CustomLink :text="link.text" :toSrc="link.to" />
                </li>
              </ul>
            </div>
            <div class="mt-4 mt-sm-0">
              <ul class="list-unstyled mb-0 d-flex flex-column gap-3">
                <li class="ts-text-primary-2 ts-fs-5-ii fw-bold mb-2">
                  {{ $store.state.footer.About.title }}
                </li>
                <li
                  v-for="link in $store.state.footer.About.link"
                  :key="link.id"
                >
                  <CustomLink :text="link.text" :toSrc="link.to" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="ts-footer__main-3">
          <nav
            class="ts-footer-social ts-fs-5-ii d-flex gap-4 align-items-center justify-content-between justify-content-xl-end"
          >
            <a
              class="ts-link text-white"
              v-for="socialLink in $store.state.footer.SocialMediaGroup
                .SocialMedia"
              :key="socialLink.id"
              :href="socialLink.href"
            >
              <img
                height="23"
                :src="
                  $store.state.baseUrl + socialLink.Image.data.attributes.url
                "
                :alt="
                  socialLink.Image.data.attributes.alternativeText == ''
                    ? socialLink.Image.data.attributes.alternativeText
                    : 'Social Media'
                "
              />
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLink from "../Helpers/CustomLink.vue";
export default {
  name: "TsFooter",
  components: {
    CustomLink,
  },
  // Api Calling Start
  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getFooter");
      }
    },
  },
  created() {
    this.$store.dispatch("getFooter");
  },
};
</script>

<style lang="scss" scoped>
.ts-link {
  font-size: clamp(13px, 2vw, 1rem);
}
@media (max-width: 991px) {
  .ts-copy-text {
    font-weight: 300;
  }
}
.ts-footer-social {
  max-width: 414px;
  margin: auto;

  .ts-link {
    display: flex;
    align-items: center;
  }
}

.ts-copy {
  font-size: 19px;
  position: relative;
  top: 3px;
}
.ts-footer__main {
  display: grid;
  gap: 3.375rem;
  grid-template-columns: auto;

  @media (min-width: 576px) and (max-width: 1100px) {
    align-items: center;
    gap: 3rem;
    grid-template-columns: auto auto;
    align-items: center;
    &-1 {
      order: 0;
    }
    &-2 {
      order: 3;
      grid-column-end: span 2;
    }
    &-3 {
      order: 1;
    }
  }
  @media (min-width: 1101px) {
    gap: 1rem;
    grid-template-columns: 245px auto 245px;
  }

  @media (max-width: 1100px) and (min-width: 768px) {
    padding-inline: 6.5625rem;
  }
  @media (max-width: 767px) and (min-width: 576px) {
    padding-inline: 3rem;
  }

  &-content {
    row-gap: 2.625rem;
    column-gap: clamp(3rem, 6vw, 4.6875rem);
    flex-wrap: wrap !important;
    // width: 100%;
    justify-content: space-between;
    @media (min-width: 1100px) {
      gap: clamp(2rem, 5vw, 5rem);
    }
    @media (min-width: 1200px) {
      gap: clamp(3rem, 8vw, 6.25rem);
    }
    @media (max-width: 1100px) {
      width: 100%;
      justify-content: space-between;
    }
  }
  @media (max-width: 475px) {
    width: 295px;
    margin: auto;
  }
}
</style>
