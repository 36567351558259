<template>
  <svg
    v-if="name === 'facebook-f'"
    xmlns="http://www.w3.org/2000/svg"
    width="11.921"
    height="23.6"
    viewBox="0 0 11.921 23.6"
  >
    <g id="XMLID_834_" transform="translate(0)">
      <path
        id="XMLID_835_"
        d="M77.084,12.569h2.587v10.65a.381.381,0,0,0,.381.381h4.386a.381.381,0,0,0,.381-.381V12.62h2.974a.381.381,0,0,0,.378-.337l.452-3.921a.381.381,0,0,0-.378-.424H84.818V5.48c0-.741.4-1.117,1.186-1.117h2.24a.381.381,0,0,0,.381-.381V.383A.381.381,0,0,0,88.244,0H85.157c-.022,0-.07,0-.141,0a5.915,5.915,0,0,0-3.868,1.458A4.056,4.056,0,0,0,79.8,5.063V7.937H77.084a.381.381,0,0,0-.381.381v3.871A.381.381,0,0,0,77.084,12.569Z"
        transform="translate(-76.703)"
        fill="#d3d3d3"
      />
    </g>
  </svg>

  <svg
    v-if="name === 'linkedin-in'"
    id="XMLID_801_"
    xmlns="http://www.w3.org/2000/svg"
    width="23.6"
    height="23.548"
    viewBox="0 0 23.6 23.548"
  >
    <path
      id="XMLID_802_"
      d="M10.045,99.73H5.308a.381.381,0,0,0-.381.381v15.22a.381.381,0,0,0,.381.381h4.738a.381.381,0,0,0,.381-.381v-15.22A.381.381,0,0,0,10.045,99.73Z"
      transform="translate(-4.552 -92.164)"
      fill="#d3d3d3"
    />
    <path
      id="XMLID_803_"
      d="M3.126.341A3.124,3.124,0,1,0,6.25,3.464,3.128,3.128,0,0,0,3.126.341Z"
      transform="translate(0 -0.341)"
      fill="#d3d3d3"
    />
    <path
      id="XMLID_804_"
      d="M115.637,94.761a5.564,5.564,0,0,0-4.163,1.747V95.52a.381.381,0,0,0-.381-.381h-4.537a.381.381,0,0,0-.381.381v15.22a.381.381,0,0,0,.381.381h4.727a.381.381,0,0,0,.381-.381V103.21c0-2.538.689-3.526,2.458-3.526,1.927,0,2.08,1.585,2.08,3.657v7.4a.381.381,0,0,0,.381.381h4.729a.381.381,0,0,0,.381-.381v-8.349C121.693,98.618,120.973,94.761,115.637,94.761Z"
      transform="translate(-98.093 -87.573)"
      fill="#d3d3d3"
    />
  </svg>

  <svg
    v-if="name === 'instagram'"
    id="instagram"
    xmlns="http://www.w3.org/2000/svg"
    width="23.548"
    height="23.548"
    viewBox="0 0 23.548 23.548"
  >
    <path
      id="XMLID_17_"
      d="M16.533,0H7.015A7.023,7.023,0,0,0,0,7.015v9.518a7.023,7.023,0,0,0,7.015,7.015h9.518a7.023,7.023,0,0,0,7.015-7.015V7.015A7.023,7.023,0,0,0,16.533,0Zm4.646,16.533a4.646,4.646,0,0,1-4.646,4.646H7.015a4.646,4.646,0,0,1-4.646-4.646V7.015A4.646,4.646,0,0,1,7.015,2.369h9.518a4.646,4.646,0,0,1,4.646,4.646v9.518Z"
      fill="#d3d3d3"
    />
    <path
      id="XMLID_81_"
      d="M139.09,133a6.09,6.09,0,1,0,6.09,6.09A6.1,6.1,0,0,0,139.09,133Zm0,9.812a3.721,3.721,0,1,1,3.721-3.721A3.721,3.721,0,0,1,139.09,142.812Z"
      transform="translate(-127.316 -127.316)"
      fill="#d3d3d3"
    />
    <ellipse
      id="XMLID_83_"
      cx="1.459"
      cy="1.459"
      rx="1.459"
      ry="1.459"
      transform="translate(16.417 4.27)"
      fill="#d3d3d3"
    />
  </svg>

  <svg
    v-if="name === 'instagram-lg'"
    xmlns="http://www.w3.org/2000/svg"
    width="55.984"
    height="55.991"
    viewBox="0 0 55.984 55.991"
  >
    <path
      id="Union_30"
      data-name="Union 30"
      d="M16.452,55.828a20.528,20.528,0,0,1-6.793-1.3,14.341,14.341,0,0,1-8.193-8.193,20.463,20.463,0,0,1-1.3-6.793C.033,36.555,0,35.6,0,28S.033,19.448.175,16.45a20.521,20.521,0,0,1,1.3-6.792A13.791,13.791,0,0,1,4.7,4.7,13.661,13.661,0,0,1,9.67,1.465a20.47,20.47,0,0,1,6.793-1.3C19.449.033,20.4,0,28,0s8.554.033,11.551.175a20.515,20.515,0,0,1,6.792,1.3A14.343,14.343,0,0,1,54.54,9.669a20.461,20.461,0,0,1,1.3,6.793c.131,2.986.142,3.938.142,11.54s-.033,8.554-.164,11.54a20.532,20.532,0,0,1-1.3,6.793,14.324,14.324,0,0,1-8.193,8.193,20.468,20.468,0,0,1-6.792,1.3c-2.986.131-3.938.164-11.541.164S19.438,55.959,16.452,55.828ZM16.714,5.25a15.426,15.426,0,0,0-5.2.963A8.7,8.7,0,0,0,8.3,8.3a8.515,8.515,0,0,0-2.1,3.216,15.341,15.341,0,0,0-.962,5.2c-.132,2.942-.164,3.839-.164,11.309s.032,8.357.164,11.31a15.408,15.408,0,0,0,.962,5.2,8.713,8.713,0,0,0,2.089,3.216,8.613,8.613,0,0,0,3.216,2.089,15.343,15.343,0,0,0,5.2.962c2.943.132,3.84.164,11.311.164s8.357-.033,11.31-.164a15.4,15.4,0,0,0,5.2-.962,9.29,9.29,0,0,0,5.317-5.316,15.362,15.362,0,0,0,.963-5.2c.131-2.953.164-3.828.164-11.3s-.033-8.356-.164-11.309a15.422,15.422,0,0,0-.963-5.2A8.7,8.7,0,0,0,47.747,8.3a8.613,8.613,0,0,0-3.216-2.089,15.361,15.361,0,0,0-5.2-.963c-2.943-.131-3.829-.164-11.31-.164S19.668,5.119,16.714,5.25ZM13.618,28A14.384,14.384,0,1,1,28,42.385,14.388,14.388,0,0,1,13.618,28Zm5.054,0A9.331,9.331,0,1,0,28,18.671,9.332,9.332,0,0,0,18.672,28ZM39.6,13.049a3.359,3.359,0,1,1,3.358,3.358A3.358,3.358,0,0,1,39.6,13.049Z"
      fill="#777"
    />
  </svg>

  <svg
    v-if="name === 'twitter'"
    id="twitter"
    xmlns="http://www.w3.org/2000/svg"
    width="24.148"
    height="19.62"
    viewBox="0 0 24.148 19.62"
  >
    <g transform="translate(0)">
      <g id="Group_538" data-name="Group 538" transform="translate(0)">
        <path
          id="Path_327"
          data-name="Path 327"
          d="M24.148,50.323A10.321,10.321,0,0,1,21.3,51.1a4.923,4.923,0,0,0,2.178-2.736,9.894,9.894,0,0,1-3.139,1.2,4.95,4.95,0,0,0-8.564,3.385,5.1,5.1,0,0,0,.115,1.129A14.013,14.013,0,0,1,1.681,48.9,4.952,4.952,0,0,0,3.2,55.519a4.889,4.889,0,0,1-2.237-.61v.054a4.973,4.973,0,0,0,3.966,4.864,4.941,4.941,0,0,1-1.3.163,4.378,4.378,0,0,1-.937-.085,5,5,0,0,0,4.626,3.449,9.947,9.947,0,0,1-6.138,2.111A9.276,9.276,0,0,1,0,65.4,13.937,13.937,0,0,0,7.595,67.62a13.993,13.993,0,0,0,14.09-14.087c0-.219-.008-.43-.018-.64A9.876,9.876,0,0,0,24.148,50.323Z"
          transform="translate(0 -48)"
          fill="#d3d3d3"
        />
      </g>
    </g>
  </svg>

  <svg
    v-if="name === 'arrow-down'"
    xmlns="http://www.w3.org/2000/svg"
    width="13.549"
    height="7.762"
    viewBox="0 0 13.549 7.762"
  >
    <path
      id="Path_825"
      data-name="Path 825"
      d="M-7718.686-232.5l-5.285,5.348-5.435-5.348"
      transform="translate(7730.82 233.914)"
      fill="none"
      stroke="#707070"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>

  <svg
    v-if="name === 'arrow-right'"
    xmlns="http://www.w3.org/2000/svg"
    width="38.43"
    height="22.346"
    viewBox="0 0 38.43 22.346"
  >
    <g
      id="Group_2540"
      data-name="Group 2540"
      transform="translate(-408.369 -734.917)"
    >
      <path
        id="Path_969"
        data-name="Path 969"
        d="M19.518,0,9.9,9.738,0,0"
        transform="translate(436.06 755.85) rotate(-90)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_970"
        data-name="Path 970"
        d="M6910.8,750.768h-36.43"
        transform="translate(-6465 -4.678)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-width="2"
      />
    </g>
  </svg>

  <svg
    v-if="name === 'location-pin'"
    xmlns="http://www.w3.org/2000/svg"
    width="17.106"
    height="22.807"
    viewBox="0 0 17.106 22.807"
  >
    <path
      id="location-pin-svgrepo-com"
      d="M12.634-.011A8.785,8.785,0,0,0,4,8.688c0,4.939,4.589,9.991,7.676,13.541A1.743,1.743,0,0,0,12.8,22.8h.054a1.731,1.731,0,0,0,1.123-.568c2.9-3.33,7.128-8.822,7.128-13.541s-3.088-8.7-8.472-8.7Zm.286,21.283a.864.864,0,0,1-.093.076.868.868,0,0,1-.095-.076l-.373-.429C9.433,17.486,5.425,12.888,5.425,8.687a7.339,7.339,0,0,1,7.209-7.274c4.867,0,7.046,3.653,7.046,7.274,0,3.189-2.275,7.424-6.761,12.585ZM12.578,4.3a4.277,4.277,0,1,0,4.277,4.277A4.277,4.277,0,0,0,12.578,4.3Zm0,7.128A2.909,2.909,0,0,1,9.694,8.55a2.851,2.851,0,1,1,2.884,2.882Z"
      transform="translate(-4 0.011)"
      fill="#00a4b6"
    />
  </svg>

  <svg
    v-if="name === 'Clock'"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g id="Complete" transform="translate(-1 -1)">
      <g id="Clock">
        <g id="Group_1559" data-name="Group 1559">
          <path
            id="Path_878"
            data-name="Path 878"
            d="M11.9,5.9v6l.1.1,2.1,2.1"
            fill="none"
            stroke="#00a4b6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <circle
            id="Circle"
            cx="10"
            cy="10"
            r="10"
            transform="translate(2 2)"
            fill="none"
            stroke="#00a4b6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </g>
    </g>
  </svg>

  <svg
    v-if="name === 'arrow-top-right'"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4787 13.2276L10.3244 4.73646L1.83354 4.58247"
      stroke="#404040"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.29289 12.2929C0.902368 12.6834 0.902368 13.3166 1.29289 13.7071C1.68342 14.0976 2.31658 14.0976 2.70711 13.7071L1.29289 12.2929ZM9.29289 4.29289L1.29289 12.2929L2.70711 13.7071L10.7071 5.70711L9.29289 4.29289Z"
      fill="#404040"
    />
  </svg>

  <svg
    v-if="name === 'chevron-right'"
    xmlns="http://www.w3.org/2000/svg"
    width="9.618"
    height="17.269"
    viewBox="0 0 9.618 17.269"
  >
    <path
      id="Path_877"
      data-name="Path 877"
      d="M-7714.966-232.5l-2.987,3.023-4.132,4.181-7.321-7.2"
      transform="translate(233.914 -7713.551) rotate(-90)"
      fill="none"
      stroke="#707070"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>

  <svg
    v-if="name === 'Complete'"
    xmlns="http://www.w3.org/2000/svg"
    width="25.469"
    height="31.335"
    viewBox="0 0 25.469 31.335"
  >
    <g id="Complete" transform="translate(-3 -0.998)">
      <g id="F-File" transform="translate(4 1.999)">
        <g id="Text">
          <g id="Group_1568" data-name="Group 1568">
            <path
              id="File"
              d="M24.533,31.333H6.933A2.933,2.933,0,0,1,4,28.4V4.934A2.933,2.933,0,0,1,6.933,2H17.346a2.933,2.933,0,0,1,2.2.88l7.187,7.627a2.933,2.933,0,0,1,.733,2.053V28.4A2.933,2.933,0,0,1,24.533,31.333Z"
              transform="translate(-4 -1.999)"
              fill="none"
              stroke="#646464"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <line
              id="Line_13"
              data-name="Line 13"
              x2="12.026"
              transform="translate(5.72 22.735)"
              fill="none"
              stroke="#646464"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <line
              id="Line_14"
              data-name="Line 14"
              x2="12.026"
              transform="translate(5.72 16.868)"
              fill="none"
              stroke="#646464"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <line
              id="Line_15"
              data-name="Line 15"
              x2="7.333"
              transform="translate(5.867 11.002)"
              fill="none"
              stroke="#646464"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

  <svg
    v-if="name === 'youtube'"
    id="youtube"
    xmlns="http://www.w3.org/2000/svg"
    width="41.651"
    height="29.163"
    viewBox="0 0 41.651 29.163"
  >
    <path
      d="M40.794-1.519A5.218,5.218,0,0,0,37.123-5.19c-3.259-.892-16.3-.892-16.3-.892s-13.037,0-16.3.858A5.324,5.324,0,0,0,.858-1.519C0,1.74,0,8.5,0,8.5s0,6.793.858,10.018A5.219,5.219,0,0,0,4.53,22.189c3.293.892,16.3.892,16.3.892s13.037,0,16.3-.858a5.218,5.218,0,0,0,3.671-3.671c.857-3.259.857-10.018.857-10.018s.034-6.793-.858-10.052ZM16.675,14.743V2.255L27.516,8.5Zm0,0"
      transform="translate(-0.001 6.082)"
      fill="#777"
    />
  </svg>

  <svg
    v-if="name === 'snapchat'"
    id="snapchat"
    xmlns="http://www.w3.org/2000/svg"
    width="34.778"
    height="32.606"
    viewBox="0 0 34.778 32.606"
  >
    <g id="Group_537" data-name="Group 537" transform="translate(0 0)">
      <path
        id="Path_322"
        data-name="Path 322"
        d="M34.1,40.336c-4.65-.765-6.745-5.528-6.83-5.73a.327.327,0,0,0-.02-.039,1.437,1.437,0,0,1-.163-1.183c.254-.6,1.228-.9,1.872-1.109.187-.059.363-.115.509-.172a3.936,3.936,0,0,0,1.387-.828,1.317,1.317,0,0,0,.422-.948,1.491,1.491,0,0,0-1.117-1.293,2.02,2.02,0,0,0-.756-.143,1.684,1.684,0,0,0-.7.143,3.953,3.953,0,0,1-1.45.413,1.331,1.331,0,0,1-.48-.1c.013-.217.026-.441.041-.672l.007-.1c.15-2.354.335-5.282-.443-7.021a9.445,9.445,0,0,0-2.128-3.045A8.9,8.9,0,0,0,21.7,16.827a10.058,10.058,0,0,0-3.971-.839h-.087l-.6.007a10.1,10.1,0,0,0-3.976.839,8.85,8.85,0,0,0-2.537,1.676,9.475,9.475,0,0,0-2.119,3.039c-.778,1.737-.593,4.665-.446,7.017v0c.015.252.033.515.048.772a1.393,1.393,0,0,1-.57.107,3.771,3.771,0,0,1-1.55-.415,1.392,1.392,0,0,0-.589-.122A2.083,2.083,0,0,0,4.26,29.2a1.441,1.441,0,0,0-.737.941,1.3,1.3,0,0,0,.5,1.219,4.275,4.275,0,0,0,1.3.741c.148.059.324.113.509.172.643.2,1.619.513,1.874,1.109a1.448,1.448,0,0,1-.165,1.183.308.308,0,0,0-.017.039,11.51,11.51,0,0,1-1.752,2.708A9.831,9.831,0,0,1,3.649,39.18,7.775,7.775,0,0,1,.682,40.334a.812.812,0,0,0-.68.848,1.03,1.03,0,0,0,.085.354v0a2.173,2.173,0,0,0,1.178,1.006,13.454,13.454,0,0,0,3.4.885,4.127,4.127,0,0,1,.209.748c.057.261.115.53.2.815a.891.891,0,0,0,.933.678,4.54,4.54,0,0,0,.8-.111,9.1,9.1,0,0,1,1.824-.207,7.925,7.925,0,0,1,1.3.111,6.2,6.2,0,0,1,2.437,1.259,7.889,7.889,0,0,0,4.8,1.872q.088,0,.176-.007c.07,0,.159.007.25.007a7.9,7.9,0,0,0,4.8-1.869l0,0a6.217,6.217,0,0,1,2.437-1.256,7.925,7.925,0,0,1,1.3-.111,9.14,9.14,0,0,1,1.824.193,4.406,4.406,0,0,0,.8.1h.039a.88.88,0,0,0,.893-.667c.083-.28.141-.541.2-.809a4.271,4.271,0,0,1,.207-.743,13.548,13.548,0,0,0,3.4-.885,2.181,2.181,0,0,0,1.176-1,1.033,1.033,0,0,0,.087-.359A.809.809,0,0,0,34.1,40.336Z"
        transform="translate(0 -15.988)"
        fill="#777"
      />
    </g>
  </svg>

  <svg
    v-if="name === 'tiktok'"
    id="tiktok"
    xmlns="http://www.w3.org/2000/svg"
    width="35.649"
    height="40.576"
    viewBox="0 0 35.649 40.576"
  >
    <path
      id="Path_876"
      data-name="Path 876"
      d="M67.053,10.55a10.223,10.223,0,0,1-6.178-2.064,10.247,10.247,0,0,1-3.887-6.178,10.3,10.3,0,0,1-.2-1.918H50.166v18.1l-.008,9.916a6.006,6.006,0,1,1-4.119-5.69V15.992a13.036,13.036,0,0,0-1.91-.141,12.7,12.7,0,0,0-9.546,4.269,12.45,12.45,0,0,0,.562,17.167,12.923,12.923,0,0,0,1.2,1.045,12.705,12.705,0,0,0,7.787,2.633,13.036,13.036,0,0,0,1.91-.14,12.662,12.662,0,0,0,7.074-3.538,12.4,12.4,0,0,0,3.721-8.827L56.8,13.653a16.737,16.737,0,0,0,3.721,2.17,16.747,16.747,0,0,0,6.551,1.321v-6.6s-.017,0-.019,0Z"
      transform="translate(-31.423 -0.39)"
      fill="#777"
    />
  </svg>

  <svg
    v-if="name === 'twitch'"
    id="twitch"
    xmlns="http://www.w3.org/2000/svg"
    width="30.965"
    height="32.31"
    viewBox="0 0 30.965 32.31"
  >
    <path
      id="Path_328"
      data-name="Path 328"
      d="M.975,5.621V28.095h7.74V32.31H12.94l4.219-4.216h6.334l8.446-8.425V0H3.085ZM5.9,2.807H29.125V18.26L24.2,23.177H16.455l-4.219,4.21v-4.21H5.9Z"
      transform="translate(-0.975 0)"
      fill="#626262"
    />
    <path
      id="Path_329"
      data-name="Path 329"
      d="M10.385,6.262H13.2v8.427H10.385Z"
      transform="translate(2.283 2.168)"
      fill="#626262"
    />
    <path
      id="Path_330"
      data-name="Path 330"
      d="M16.133,6.262h2.815v8.427H16.133Z"
      transform="translate(4.273 2.168)"
      fill="#626262"
    />
  </svg>

  <svg
    v-if="name === 'copy'"
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="18"
    viewBox="0 0 15 18"
  >
    <g
      id="Group_1346"
      data-name="Group 1346"
      transform="translate(0.057 -0.398)"
    >
      <g
        id="Rectangle_633"
        data-name="Rectangle 633"
        transform="translate(-0.057 0.398)"
        fill="#f4f4f4"
        stroke="#777"
        stroke-width="1"
      >
        <rect width="12" height="15" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="11" height="14" rx="0.5" fill="none" />
      </g>
      <g
        id="Rectangle_634"
        data-name="Rectangle 634"
        transform="translate(3.943 2.398)"
        fill="#f4f4f4"
        stroke="#777"
        stroke-width="1"
      >
        <rect width="11" height="16" rx="1" stroke="none" />
        <rect x="0.5" y="0.5" width="10" height="15" rx="0.5" fill="none" />
      </g>
    </g>
  </svg>

  <svg
    v-if="name === 'eye-off'"
    id="eye-off"
    xmlns="http://www.w3.org/2000/svg"
    width="15.755"
    height="11.623"
    viewBox="0 0 15.755 11.623"
  >
    <path
      d="M14.632,5.24A.726.726,0,0,0,13.6,4.213l-3.1,3.1a2.906,2.906,0,0,0-3.98,3.98l-3.1,3.1A.726.726,0,1,0,4.462,15.41l3.1-3.1a2.906,2.906,0,0,0,3.98-3.98ZM9.41,8.408a1.454,1.454,0,0,0-1.78,1.78Zm-.753,2.807,1.78-1.78a1.454,1.454,0,0,1-1.78,1.78ZM11.768,4.6A6.754,6.754,0,0,0,9.033,4,7.9,7.9,0,0,0,3.947,6.111,14.429,14.429,0,0,0,1.38,9.063a1.363,1.363,0,0,0,0,1.5,14.75,14.75,0,0,0,2,2.429L4.4,11.961a13.3,13.3,0,0,1-1.777-2.15,12.97,12.97,0,0,1,2.268-2.6,6.464,6.464,0,0,1,4.14-1.76,5.129,5.129,0,0,1,1.612.266ZM9.033,14.17A5.129,5.129,0,0,1,7.421,13.9L6.3,15.027a6.754,6.754,0,0,0,2.735.6,7.9,7.9,0,0,0,5.086-2.111,14.431,14.431,0,0,0,2.567-2.952,1.363,1.363,0,0,0,0-1.5,14.749,14.749,0,0,0-2-2.429L13.663,7.662a13.3,13.3,0,0,1,1.777,2.15,12.971,12.971,0,0,1-2.268,2.6A6.464,6.464,0,0,1,9.033,14.17Z"
      transform="translate(-1.156 -4)"
      fill="#b7b7b7"
      fill-rule="evenodd"
    />
  </svg>

  <svg
    v-if="name === 'eye-on'"
    xmlns="http://www.w3.org/2000/svg"
    width="14.189"
    height="10.729"
    viewBox="0 0 14.189 10.729"
  >
    <g id="SVGRepo_iconCarrier" transform="translate(-6.931 -14.712)">
      <path
        id="Path_942"
        data-name="Path 942"
        d="M31.648,29.6a2.042,2.042,0,1,1-2.042-2.042A2.042,2.042,0,0,1,31.648,29.6Z"
        transform="translate(-15.582 -9.528)"
        fill="none"
        stroke="#b7b7b7"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
      <path
        id="Path_943"
        data-name="Path 943"
        d="M14.025,15.313a6.809,6.809,0,0,0-6.495,4.764,6.809,6.809,0,0,0,12.989,0A6.809,6.809,0,0,0,14.025,15.313Z"
        fill="none"
        stroke="#b7b7b7"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.2"
      />
    </g>
  </svg>

  <svg
    v-if="name === 'chevron-left'"
    id="Capa_1"
    enable-background="new 0 0 58.013 58.013"
    height="24"
    viewBox="0 0 58.013 58.013"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        id="XMLID_423_"
        d="m44.8 51.113c1.6 1.5 1.6 4.1.1 5.7-.8.8-1.9 1.2-2.9 1.2s-2-.4-2.8-1.1l-26-25c-.8-.8-1.2-1.8-1.2-2.9s.4-2.1 1.2-2.9l26-25c1.6-1.5 4.1-1.5 5.7.1 1.5 1.6 1.5 4.1-.1 5.7l-23 22.1z"
        fill="#504f4f"
      />
    </g>
  </svg>

  <svg
    v-if="name === 'check-circle-filled'"
    xmlns="http://www.w3.org/2000/svg"
    width="14.855"
    height="14.857"
    viewBox="0 0 14.855 14.857"
  >
    <path
      id="Path_371"
      data-name="Path 371"
      d="M7.184,14.364a7.182,7.182,0,1,1,7.179-7.172A7.188,7.188,0,0,1,7.184,14.364ZM3.235,7a.774.774,0,0,0,.222.519c.618.717,1.232,1.437,1.85,2.154a.8.8,0,0,0,1.175.091c.3-.265.6-.541.9-.813q1.746-1.589,3.491-3.179A.8.8,0,1,0,9.747,4.629Q7.957,6.261,6.169,7.9c-.123.113-.193.113-.3-.019-.418-.5-.842-.993-1.277-1.477a.75.75,0,0,0-.9-.16A.769.769,0,0,0,3.235,7Z"
      transform="translate(0 0.501) rotate(-2)"
      fill="#00a4b6"
    />
  </svg>
  <svg
    v-if="name === 'check-circle-filled-lg'"
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 14.855 14.857"
  >
    <path
      id="Path_371"
      data-name="Path 371"
      d="M7.184,14.364a7.182,7.182,0,1,1,7.179-7.172A7.188,7.188,0,0,1,7.184,14.364ZM3.235,7a.774.774,0,0,0,.222.519c.618.717,1.232,1.437,1.85,2.154a.8.8,0,0,0,1.175.091c.3-.265.6-.541.9-.813q1.746-1.589,3.491-3.179A.8.8,0,1,0,9.747,4.629Q7.957,6.261,6.169,7.9c-.123.113-.193.113-.3-.019-.418-.5-.842-.993-1.277-1.477a.75.75,0,0,0-.9-.16A.769.769,0,0,0,3.235,7Z"
      transform="translate(0 0.501) rotate(-2)"
      fill="#00a4b6"
    />
  </svg>
  <svg
    v-if="name === 'x-circle-filled-lg'"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <g
      id="Group_2653"
      data-name="Group 2653"
      transform="translate(-1037 -2832)"
    >
      <g id="Group_2652" data-name="Group 2652">
        <circle
          id="Ellipse_62"
          data-name="Ellipse 62"
          cx="14"
          cy="14"
          r="14"
          transform="translate(1037 2832)"
          fill="#a6a6a6"
        />
        <g id="Group_2654" data-name="Group 2654">
          <path
            id="Union_75"
            data-name="Union 75"
            d="M0,0,9,8"
            transform="translate(1046 2842.5)"
            fill="#343434"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
        </g>
      </g>
      <path
        id="Union_76"
        data-name="Union 76"
        d="M0,8,9,0"
        transform="translate(1055 2850.5) rotate(180)"
        fill="#343434"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "CustomIcons",
  props: {
    name: String,
  },
};
</script>
