<template>
  <div
    class="ts-overview-card ts-overview-card--lg"
    :style="{
      backgroundImage:
        'url(' +
        $store.state.baseUrl +
        $store.state.overviewPage.EfficientMatching.backgroundImage.data
          .attributes.url +
        ')',
    }"
  >
    <div class="ts-overview-card__content text-center">
      <img
        width="230"
        class="ts-image mw-100 ts-bg-2 mx-auto mb-03 mb-lg-07"
        :src="
          $store.state.baseUrl +
          $store.state.overviewPage.EfficientMatching.Image.data.attributes.url
        "
        alt="alternativeText"
      />
      <div class="ts-text">
        <h1 class="ts-fs-6-ii mb-0 fw-semibold text-center">
          {{ $store.state.overviewPage.EfficientMatching.title }}
        </h1>
        <p class="ts-fs-7-ii mb-0 text-center">
          {{ $store.state.overviewPage.EfficientMatching.description }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EfficientMatching",
};
</script>
<style lang="scss" scoped>
@media (max-width: 991px) {
  .ts-overview-card__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    .ts-image {
      margin-block: auto;
    }
    .ts-text {
      margin-top: auto;
    }
  }
}
</style>
