<template>
  <section class="ts-creator-love-us" v-if="$store.state.creatorLoveUs">
    <slot></slot>
    <swiper
      :slidesPerView="1"
      :spaceBetween="16"
      :loop="true"
      :pagination="{
        clickable: true,
      }"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :breakpoints="{
        '700': {
          slidesPerView: 2,
          spaceBetween: 28,
        },
        '1100': {
          slidesPerView: 3,
          spaceBetween: 36,
        },
      }"
      :modules="modules"
      class="ts-custom-swiper tsCreatorLoveSwiper"
      v-if="$store.state.creatorLoveUs"
    >
      <swiper-slide v-for="card in $store.state.creatorLoveUs" :key="card.id">
        <div
          class="ts-creator-card ts-bg-primary-3 d-flex flex-column gap-4 justify-content-between"
        >
          <div>
            <img
              class="ts-creator-card__qoute-img mb-06 mb-lg-08"
              src="/images/double-qoute.svg"
              alt="alternativeText"
            />
            <h5
              class="ts-creator-testimonial ts-fs-6 ts-text-gray-2 fw-light ts-fs-7 mb-03"
            >
              {{ card.attributes.Testimonial }}
            </h5>
          </div>
          <div class="text-center">
            <img
              class="ts-creator-card__user-img rounded-circle mb-02"
              :src="
                $store.state.baseUrl +
                card.attributes.Contact.Image.data.attributes.url
              "
              :alt="
                card.attributes.Contact.Image.data.attributes.alternativeText
              "
            />
            <p class="ts-text-gray-6 text-center mb-02 mb-lg-03">
              {{ card.attributes.Contact.Name }}
            </p>
            <div
              class="ts-social-links d-flex gap-3 gap-sm-4 justify-content-center"
            >
              <a
                class="ts-text-gray-4"
                v-for="link in card.attributes.SocialMedia.SocialMediaLink"
                :key="link.id"
                :href="link.Link"
              >
                <img
                  class="ts-social-links__img"
                  :src="$store.state.baseUrl + link.Image.data.attributes.url"
                  :alt="link.Image.data.attributes.alternativeText"
                />
              </a>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

export default {
  name: "CreatorsReviews",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getCreatorLoveUs");
      }
    },
  },
  created() {
    this.$store.dispatch("getCreatorLoveUs");
  },
};
</script>

<style scoped lang="scss">
.ts-creator-testimonial {
  height: 100px;
  overflow-y: scroll;
  text-align: justify;
  &::-webkit-scrollbar {
    display: none;
  }
  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
.ts-social-links {
  &__img {
    width: clamp(21px, 4.4vw, 27px);
  }
}

.rtl {
  .ts-creator-testimonial {
    text-align: end;
    text-align: right; /* Fallback for older browsers */
  }
}
</style>
