<template>
  <LoadingScreen v-if="!$store.state.notFound" />
  <div>
    <section
      class="ts-404 d-flex align-items-end align-items-lg-center"
      v-if="$store.state.notFound"
    >
      <div class="w-100">
        <div class="ts-container-fluid container-fluid">
          <div class="row row-cols-lg-2">
            <div class="ts-text text-center text-lg-start">
              <h1 class="fw-bold mb-3">{{ $store.state.notFound.title }}</h1>
              <p class="ts-fs-4-ii mb-07 mb-lg-11">
                {{ $store.state.notFound.description }}
              </p>
              <div
                class="d-flex justify-content-center justify-content-lg-start"
              >
                <a
                  href="/"
                  class="d-flex align-items-center gap-5 ts-btn ts-btn--sm-lg ts-btn-primary rounded-pill px-4"
                >
                  {{ $store.state.notFound.link.text }}
                  <Icon name="arrow-right" />
                </a>
              </div>
            </div>
            <div>
              <div class="ts-image">
                <img
                  :src="getImageUrl()"
                  :alt="
                    $store.state.notFound.ImageLarge.data.attributes
                      .alternativeText
                  "
                  class="ts-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import Icon from "../assets/icons/index.vue";

export default {
  name: "NotFound",
  components: {
    LoadingScreen,
    Icon,
  },
  data() {
    return {
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
    imageUrl() {
      const baseUrl = this.$store.state.baseUrl;

      if (this.screenWidth < 576) {
        return (
          baseUrl + this.$store.state.notFound.ImageSmall.data.attributes.url
        );
      } else if (this.screenWidth < 992) {
        return (
          baseUrl + this.$store.state.notFound.ImageMedium.data.attributes.url
        );
      } else {
        return (
          baseUrl + this.$store.state.notFound.ImageLarge.data.attributes.url
        );
      }
    },
  },
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getPageNotFound");
      }
    },
    screenWidth() {
      // Trigger a re-render when the screen width changes
      this.$forceUpdate();
    },
  },
  created() {
    this.$store.dispatch("getPageNotFound");

    // Add event listener for screen resize
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    // Remove event listener when component is destroyed
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getImageUrl() {
      return this.imageUrl;
    },
    handleResize() {
      // Update screenWidth on screen resize
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.ts-404 {
  min-height: calc(100vh - 77px);
  padding-block: 5rem;
}
h1 {
  font-size: clamp(2.8125rem, 4.5vw, 5.0625rem);
}

.ts-text {
  @media (min-width: 992px) {
    max-width: 500px;
  }
}

.ts-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
</style>
