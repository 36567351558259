<template>
  <!-- <LoadingScreen v-if="!$store.state.creatorFaq" /> -->
  <!-- <div v-else> -->
  <div>
    <CreatorFaqContent />
  </div>
</template>
<script>
// import LoadingScreen from "../components/Common/LoadingScreen.vue";
import CreatorFaqContent from "../components/Faq/CreatorFaqContent.vue";

export default {
  name: "CreatorFaq",
  components: {
    // LoadingScreen,
    CreatorFaqContent,
  },
};
</script>
